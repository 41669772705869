import type { APIDesignerStore } from '..';
import type { $TSFixMe } from '@readme/iso';
import type { OpenAPIV3 } from 'openapi-types';
import type { StateCreator } from 'zustand';

import { codes } from '@readme/http-status-codes';
import Oas from 'oas';

import { actionLog } from '@core/store/util';

interface AugmentedResponseExample {
  /**
   * Should be a concatenation of the status code and the summary (i.e., `namedId`).
   *
   * If the summary doesn't exist (e.g., if a single example is documented using the `example` object),
   * it will just be the status code.
   *
   * @example '200_example1'
   * @example '200'
   */
  id: string;

  /**
   * The media type of the example.
   *
   * @example 'application/json'
   */
  mediaType: string;

  /**
   * The summary (e.g., the example key in the `examples` object`) of the example.
   *
   * If the example is documented using the `example` object, this will be `undefined`.
   *
   * @example 'example1'
   */
  namedId?: string;

  /**
   * The status code of the example.
   *
   * @example '200'
   */
  status: string;
}

export interface ResponseExampleEditorSliceState {
  /**
   * The currently selected response example.
   */
  selectedExample: AugmentedResponseExample | null;
}

export interface MediaTypeExample {
  description?: string;
  summary?: string;
  title?: string;
  value: unknown;
}

export interface ResponseExampleEditorSliceActions {
  /**
   * Adds a custom response example to the current operation.
   */
  addResponseExample: () => void;

  /**
   * Deletes the currently selected example.
   */
  deleteResponseExample: () => void;

  /**
   * Returns the first example in the operation.
   */
  getFirstExample: () => AugmentedResponseExample;

  /**
   * Returns the response example properties on the operation object.
   */
  getOperationResponseProperties: (
    contentType: string,
    statusCode: string,
  ) => {
    hasContentType: boolean;
    hasCustomDescription: boolean;
    hasExamplesObject: boolean;
    hasMultipleContentTypes: boolean;
    hasMultipleExamples: boolean;
    hasResponseObject: boolean;
    hasStatusCode: boolean;
  };

  /**
   * Returns the properties of the currently selected example.
   */
  getSelectedExampleProperties: () => {
    currentContentType: AugmentedResponseExample['mediaType'];
    currentStatusCode: AugmentedResponseExample['status'];
    namedId: AugmentedResponseExample['namedId'];
  };

  initialize: () => void;

  /**
   * Updates the currently selected example
   */
  setSelectedExample: ({ mediaType, status, id, namedId }) => void;

  /**
   * Updates the content-type of the currently selected example.
   */
  updateContentType: (contentType: string) => void;

  /**
   * Updates the name of the currently selected example.
   */
  updateResponseExampleName: (exampleName: string) => void;

  /**
   * Updates the code of the currently selected example.
   */
  updateSnippet: (value: string) => void;

  /**
   * Updates the status code of the currently selected example.
   */
  updateStatusCode: (statusCode: string) => void;
}

export interface ResponseExampleEditorSlice {
  /**
   * State slice containing fields and actions that pertain to the Response Example Editor
   */
  responseExampleEditor: ResponseExampleEditorSliceActions & ResponseExampleEditorSliceState;
}

export const initialState: ResponseExampleEditorSliceState = {
  selectedExample: null,
};

/**
 * Response Example Editor state slice containing fields related to the Manual Editor — Response Example Editor.
 */
export const createResponseExampleEditorSlice: StateCreator<
  APIDesignerStore & ResponseExampleEditorSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ResponseExampleEditorSlice
> = (set, get) => ({
  responseExampleEditor: {
    ...initialState,

    /**
     * Cases for adding a new response example:
     * 1. If the operation doesn't have any responses object, we want to create one
     * 2. If the status code doesn't exist, we want to create the status code
     * 3. If the status code exists, we want to check if the content type exists.
     * 4. If the content type doesn't exist, we'll create it
     * 5. If the content type exists, we want to check if the example exists
     * 6. If the examples object doesn't exist, we'll create it
     * 7. If the examples object exists, we'll append the example
     * 8. If the operation uses an `example` object, we need to convert it to `examples` and add the new example
     */
    addResponseExample: () => {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      // Constructing the scaffolding for a new example
      const newStatus = '200';
      const newContentType = 'application/json';

      // https://spec.openapis.org/oas/v3.1.0#response-object
      const currentResponseObject = operation?.responses?.[newStatus] as OpenAPIV3.ResponseObject | undefined;

      // Ensuring the example name is unique
      const baseExampleLabel = 'New Example';
      let newExampleCount = 1;
      let newExampleLabel = baseExampleLabel;
      const currentExampleLabels = Object.keys(currentResponseObject?.content?.[newContentType]?.examples || {});

      while (currentExampleLabels.includes(newExampleLabel)) {
        newExampleLabel = `${baseExampleLabel} ${newExampleCount}`;
        newExampleCount += 1;
      }

      const newExampleValue = {
        summary: newExampleLabel,
        value: '{}',
      };

      const newFullExample = {
        description: 'OK',
        content: {
          'application/json': {
            examples: {
              [newExampleLabel]: newExampleValue,
            },
          },
        },
      };

      // Determining which properties exist on the operation object
      const { hasResponseObject, hasStatusCode, hasContentType, hasExamplesObject } =
        get().responseExampleEditor.getOperationResponseProperties(newContentType, newStatus);

      const hasExampleObject = !!currentResponseObject?.content?.[newContentType]?.example;

      // Logic that covers all the cases for adding a new example
      if (!hasResponseObject) {
        // Adding Case #1
        newOperation!.responses = {
          [newStatus]: newFullExample,
        };
      } else if (hasResponseObject && !hasStatusCode) {
        // Adding Case #2
        newOperation!.responses![newStatus] = newFullExample;
      } else if (hasResponseObject && hasStatusCode && !hasContentType) {
        // Adding Case #4
        if ((newOperation!.responses?.[newStatus] as OpenAPIV3.ResponseObject).content) {
          (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject).content![newContentType] = {
            examples: {
              [newExampleLabel]: newExampleValue,
            },
          };
        } else {
          (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject).content = {
            [newContentType]: {
              examples: {
                [newExampleLabel]: newExampleValue,
              },
            },
          };
        }
      } else if (hasResponseObject && hasStatusCode && hasContentType && !hasExampleObject && !hasExamplesObject) {
        // Adding Case #6
        (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject).content![newContentType]!.examples = {
          [newExampleLabel]: newExampleValue,
        };
      } else if (hasResponseObject && hasStatusCode && hasContentType && hasExampleObject && !hasExamplesObject) {
        // Adding Case #8
        const oldExample = currentResponseObject?.content?.[newContentType]?.example;

        newOperation!.responses![newStatus] = {
          content: {
            [newContentType]: {
              examples: {
                [`exampleFromExisting${newStatus}`]: {
                  value: oldExample,
                },
                [newExampleLabel]: newExampleValue,
              },
            },
          },
          description: 'OK',
        };

        delete (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject)?.content?.[newContentType]?.example;
      } else if (hasResponseObject && hasStatusCode && hasContentType && hasExamplesObject) {
        // Adding Case #7
        (newOperation!.responses![newStatus] as OpenAPIV3.ResponseObject).content![newContentType]!.examples![
          newExampleLabel
        ] = newExampleValue;
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          state.responseExampleEditor.selectedExample = {
            mediaType: newContentType,
            namedId: newExampleLabel,
            status: newStatus.toString(),
            id: newStatus.toString().concat('_', newExampleLabel),
          };
        },
        false,
        actionLog('adding response example'),
      );
    },

    /**
     * Cases for deleting:
     * 1. If the content-type has multiple examples, we only want to delete the current example
     * 2. If there is only one example, we can delete that media type object
     * 3. If there is only one example and one content-type and a custom description, we can delete that media type object
     * 4. If there is only one example and one content-type and no custom description, we can delete the entire response object
     */
    deleteResponseExample: () => {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      const { currentStatusCode, currentContentType, namedId } =
        get().responseExampleEditor.getSelectedExampleProperties();

      // Determining if the operation has multiple content types or multiple examples
      const { hasMultipleContentTypes, hasMultipleExamples, hasCustomDescription } =
        get().responseExampleEditor.getOperationResponseProperties(currentContentType, currentStatusCode);

      if (hasMultipleExamples) {
        // Deleting Case #1
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)!.content![currentContentType]!
          .examples![namedId as string];
      } else if (hasMultipleContentTypes && !hasMultipleExamples) {
        // Deleting Case #2
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)!.content![currentContentType];
      } else if (!hasMultipleContentTypes && !hasMultipleExamples && hasCustomDescription) {
        // Deleting Case #3
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)!.content![currentContentType];
      } else if (!hasMultipleContentTypes && !hasMultipleExamples && !hasCustomDescription) {
        // Deleting Case #4
        delete newOperation!.responses![currentStatusCode];
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
        },
        false,
        actionLog('deleteResponseExample'),
      );

      const firstExample = get().responseExampleEditor.getFirstExample();

      set(
        state => {
          state.responseExampleEditor.selectedExample =
            firstExample?.mediaType && firstExample?.status ? firstExample : null;
        },
        false,
        actionLog('setting selected response example to first available example', firstExample),
      );
    },

    getFirstExample: () => {
      const { currentPath, currentMethod } = get().getCurrentOperation();

      const oas = new Oas(get().apiObject?.schema || '{}');
      const operation = oas.operation(currentPath, currentMethod);

      const firstRawExample = operation?.getResponseExamples()[0];
      const firstStatus = firstRawExample?.status || '';
      const firstMediaType = firstRawExample ? Object.keys(firstRawExample.mediaTypes)[0] : '';
      const firstNamedId = firstMediaType ? firstRawExample.mediaTypes[firstMediaType][0]?.summary : '';

      return {
        id: firstNamedId ? firstStatus.concat('_', firstNamedId) : firstStatus,
        mediaType: firstMediaType,
        namedId: firstNamedId,
        status: firstStatus,
      };
    },

    getOperationResponseProperties: (contentType: string, statusCode: string) => {
      const { operation } = get().getCurrentOperation();

      // https://spec.openapis.org/oas/v3.1.0#responses-object
      const hasResponseObject = !!operation?.responses || false;

      // https://spec.openapis.org/oas/v3.1.0#response-object
      const responseObject = operation?.responses?.[statusCode] as OpenAPIV3.ResponseObject | undefined;

      const hasStatusCode = !!responseObject || false;
      const hasContentType = !!responseObject?.content?.[contentType] || false;
      const hasCustomDescription = responseObject?.description !== codes[statusCode][0];
      const hasMultipleContentTypes = Object.keys(responseObject?.content || {}).length > 1;

      // https://spec.openapis.org/oas/v3.1.0#media-type-object
      const mediaTypeObject = responseObject?.content?.[contentType];

      const hasExamplesObject = !!mediaTypeObject?.examples || false;
      const hasMultipleExamples = Object.keys(mediaTypeObject?.examples || {}).length > 1;

      return {
        hasResponseObject,
        hasStatusCode,
        hasContentType,
        hasCustomDescription,
        hasMultipleContentTypes,
        hasExamplesObject,
        hasMultipleExamples,
      };
    },

    getSelectedExampleProperties: () => {
      const namedId = get().responseExampleEditor.selectedExample!.namedId;
      const currentStatusCode = get().responseExampleEditor.selectedExample!.status;
      const currentContentType = get().responseExampleEditor.selectedExample!.mediaType;

      return { namedId, currentStatusCode, currentContentType };
    },

    initialize: () => {
      const firstExample = get().responseExampleEditor.getFirstExample();

      set(
        state => {
          // If the operation has an example, let's select it. Otherwise, clear the selected example.
          if (!!firstExample?.mediaType && !!firstExample?.status) {
            state.responseExampleEditor.selectedExample = firstExample;
          } else {
            state.responseExampleEditor.selectedExample = null;
          }
        },
        false,
        actionLog('initialize response example editor slice'),
      );
    },

    setSelectedExample({ mediaType, status, id, namedId }) {
      set(
        state => {
          state.responseExampleEditor.selectedExample = {
            id,
            mediaType,
            namedId,
            status,
          };
        },
        false,
        actionLog('setting selected response example', { mediaType, status, id, namedId }),
      );
    },

    /**
     * Cases for adding:
     * 1. If content-type doesn't exist, we want to create a new content-type
     * 2. If content-type does exist, we want to just append an example
     *
     * Cases for deleting:
     * 1. If the content-type has multiple examples, we only want to delete the current example
     * 2. If the content-type only has one example,  we can delete the content-type
     *
     * NOTE: Status code should always exist since the user should currently be on an example
     */
    updateContentType(contentType: string) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      // Covering the adding cases
      const { currentStatusCode, currentContentType, namedId } =
        get().responseExampleEditor.getSelectedExampleProperties();

      const hasNewContentType =
        (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[contentType] || false;

      let newLabel;
      if (!namedId) {
        newLabel = `exampleFromExisting${currentStatusCode}`;
      }

      const hasExamplesObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.examples;
      const hasExampleObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.example;

      let code;
      if (hasExamplesObject) {
        code = (
          (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
            ?.examples?.[namedId || newLabel] as OpenAPIV3.ExampleObject
        )?.value;
      } else if (hasExampleObject) {
        code = (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
          ?.example;
      }

      if (!hasNewContentType) {
        // Adding Case #1
        (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![contentType] = {
          examples: { [namedId || newLabel]: { value: code } },
        };
      } else if (hasNewContentType) {
        // Adding Case #2
        (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![contentType].examples![
          namedId || newLabel
        ] = { value: code };
      }

      // Covering the deleting cases
      const { hasMultipleExamples } = get().responseExampleEditor.getOperationResponseProperties(
        currentContentType,
        currentStatusCode,
      );

      if (hasMultipleExamples) {
        // Deleting Case #1
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType]!
          .examples![namedId as string];
      } else if (!hasMultipleExamples) {
        // Deleting Case #2
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType];
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          state.responseExampleEditor.selectedExample = {
            mediaType: contentType,
            namedId,
            status: currentStatusCode,
            id: get().responseExampleEditor.selectedExample!.id,
          };
        },
        false,
        actionLog('updateContentType'),
      );
    },

    updateResponseExampleName(exampleName: string) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();

      const newOperation = structuredClone(operation);
      const selectedExample = get().responseExampleEditor.selectedExample;

      if (!selectedExample || !newOperation!.responses![selectedExample.status]) {
        // Should never get here, but making ts happy
        throw new Error('Cannot find selected example in response object.');
      }

      // Casting the response object to an OpenAPIV3.ResponseObject since we know it's not a $ref
      const responseObject = newOperation!.responses![selectedExample.status] as OpenAPIV3.ResponseObject;

      if (
        !responseObject.content![selectedExample.mediaType] ||
        !selectedExample.namedId ||
        !responseObject.content![selectedExample.mediaType]!.examples![selectedExample.namedId]
      ) {
        // Should never get here, but making ts happy
        throw new Error('Cannot find selected example in response object.');
      }

      // Make sure the example doesn't overwrite any existing ones
      // If there is already an example with this name, we'll append a number to the end of the name until we find a unique name
      let newExampleName = exampleName;
      let exampleIndex = 1;
      while (responseObject.content![selectedExample.mediaType]!.examples![newExampleName]) {
        newExampleName = `${exampleName} ${exampleIndex}`;
        exampleIndex += 1;
      }

      // Casting the example object to an OpenAPIV3.ExampleObject since we know it's not a $ref
      const newExample = responseObject.content![selectedExample.mediaType]!.examples![
        selectedExample.namedId
      ] as OpenAPIV3.ExampleObject;
      newExample.summary = newExampleName;
      responseObject.content![selectedExample.mediaType]!.examples![newExampleName] = newExample;
      delete responseObject.content![selectedExample.mediaType]!.examples![selectedExample.namedId];
      newOperation!.responses![selectedExample.status] = responseObject;
      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          state.responseExampleEditor.selectedExample!.namedId = newExampleName;
          state.responseExampleEditor.selectedExample!.id = `${selectedExample.status}_${newExampleName}`;
        },
        false,
        actionLog('updateResponseExampleName'),
      );
    },

    /**
     * NOTE:
     * Since we're updating a snippet on an existing example, `selectedExample` will always exist
     */
    updateSnippet(value) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      const { currentStatusCode, currentContentType, namedId } =
        get().responseExampleEditor.getSelectedExampleProperties();

      let currentValue: $TSFixMe = value;

      if (currentContentType === 'application/json') {
        try {
          currentValue = JSON.parse(value);
        } catch (e) {
          /* empty */
        }
      }

      // https://spec.openapis.org/oas/v3.1.0#response-object
      const responseObject = operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject;

      const hasExamplesObject = !!responseObject?.content?.[currentContentType]?.examples;
      const hasExampleObject = !!responseObject?.content?.[currentContentType]?.example;

      let code;
      if (hasExamplesObject) {
        code = (responseObject?.content?.[currentContentType]?.examples?.[namedId as string] as OpenAPIV3.ExampleObject)
          ?.value;
      } else if (hasExampleObject) {
        code = responseObject?.content?.[currentContentType]?.example;
      }

      if (code) {
        if (hasExamplesObject) {
          (
            (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType]
              .examples![namedId as string] as OpenAPIV3.ExampleObject
          ).value = currentValue;
        } else if (hasExampleObject) {
          (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![
            currentContentType
          ].example = currentValue;
        }
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
        },
        false,
        actionLog('updateSnippet', { value }),
      );
    },

    /**
     * Cases for adding:
     * 1. If a status code does not exist, we want to create the new response object.
     * 2. If a status code exists, but the `content` object does not, we want to create a new `content` object.
     * 3. If a status code and `content` object exists, but content-type does not, we want to create a new content-type.
     * 4. If a status code, `content` object, and content-type exists, and it's not using an `example` object, we just want to append the example.
     * 5. If a status code, `content` object, and content-type exists but it's using an `example` object, we want to convert it.
     *
     * Cases for deleting:
     * 1. If the status code has multiple examples, we only want to delete the current example.
     * 2. If the status code has only one example, but multiple content-types, we want to delete that content-type.
     * 3. If the status code has only one example and one content-type, we want to just delete the status code.
     *
     * NOTE:
     * `selectedExample` should always exist since the user won't be able to update the status code without selecting an example
     * `operation.responses` should also exist since you won't be able to update the status code without already having a response object
     */
    updateStatusCode(statusCode: string) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();
      const newOperation = structuredClone(operation);

      // Covering the adding cases
      const { currentStatusCode, currentContentType, namedId } =
        get().responseExampleEditor.getSelectedExampleProperties();

      let updatedNameId = namedId || `exampleFromExisting${statusCode}`;

      const hasNewStatusCode = !!newOperation!.responses![statusCode] || false;
      const newStatusUsingExampleObject = !!(newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject)
        ?.content![currentContentType].example;
      const hasNewContent = (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject)?.content;
      const hasNewContentType = (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ];

      const hasExamplesObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.examples;
      const hasExampleObject = !!(operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[
        currentContentType
      ]?.example;

      let code;
      if (hasExamplesObject) {
        code = (
          (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
            ?.examples?.[updatedNameId] as OpenAPIV3.ExampleObject
        )?.value;
      } else if (hasExampleObject) {
        code = (operation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
          ?.example;
      }

      if (!hasNewStatusCode) {
        updatedNameId = namedId || `exampleFromExisting${currentStatusCode}`;
        // Adding Case #1
        newOperation!.responses![statusCode] = {
          description: codes[statusCode][0],
          content: {
            [currentContentType]: {
              examples: { [updatedNameId]: { value: code } },
            },
          },
        };
      } else if (hasNewStatusCode && !hasNewContent) {
        // Adding Case #2
        (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject).content = {
          [currentContentType]: {
            examples: { [updatedNameId]: { value: code } },
          },
        };
      } else if (hasNewStatusCode && hasNewContent && !hasNewContentType) {
        // Adding Case #3
        (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject).content![currentContentType] = {
          examples: { [updatedNameId]: { value: code } },
        };
      } else if (hasNewStatusCode && hasNewContent && hasNewContentType && !newStatusUsingExampleObject) {
        // Adding Case #4
        (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject).content![currentContentType].examples![
          updatedNameId
        ] = {
          value: code,
        };
      } else if (hasNewStatusCode && hasNewContent && hasNewContentType && newStatusUsingExampleObject) {
        const oldExample = (operation!.responses![statusCode] as OpenAPIV3.ResponseObject)?.content?.[
          currentContentType
        ]?.example;

        // Converting the original `example` object to an `examples` object
        newOperation!.responses![statusCode] = {
          content: {
            [currentContentType]: {
              examples: {
                [`exampleFromExisting${currentStatusCode}`]: {
                  value: oldExample,
                },
              },
            },
          },
          description: codes[currentStatusCode][0],
        };

        // Appending the new example
        (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject).content![currentContentType].examples![
          updatedNameId
        ] = {
          value: code,
        };

        // Deleting the old `example` object
        delete (newOperation!.responses![statusCode] as OpenAPIV3.ResponseObject)?.content?.[currentContentType]
          ?.example;
      }

      // Covering the deleting cases
      const { hasStatusCode, hasMultipleContentTypes, hasMultipleExamples } =
        get().responseExampleEditor.getOperationResponseProperties(currentContentType, currentStatusCode);

      if (hasStatusCode && hasMultipleExamples) {
        // Deleting Case #1
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType]!
          .examples![namedId as string];
      } else if (hasStatusCode && hasMultipleContentTypes && !hasMultipleExamples) {
        // Deleting Case #2
        delete (newOperation!.responses![currentStatusCode] as OpenAPIV3.ResponseObject).content![currentContentType];
      } else if (hasStatusCode && !hasMultipleContentTypes && !hasMultipleExamples) {
        // Deleting Case #3
        delete newOperation!.responses![currentStatusCode];
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          state.responseExampleEditor.selectedExample = {
            id: statusCode.concat('_', updatedNameId),
            mediaType: currentContentType,
            namedId: updatedNameId,
            status: statusCode,
          };
        },
        false,
        actionLog('updating status code', { statusCode }),
      );
    },
  },
});
