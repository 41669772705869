import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

export default function objectDifference<T extends object, U extends object>(obj1: T, obj2: U): Partial<T & U> {
  const obj1Entries = Object.entries(obj1);
  const obj2Entries = Object.entries(obj2);

  const obj1Difference = differenceWith(obj1Entries, obj2Entries, isEqual);
  const obj2Difference = differenceWith(obj2Entries, obj1Entries, isEqual);
  const difference = { ...Object.fromEntries(obj1Difference), ...Object.fromEntries(obj2Difference) };

  return difference as Partial<T & U>;
}
