import type { ErrorType } from '@readme/api/src/core/legacy_mappings/error';
import type { EditProjectType, ReadProjectType } from '@readme/api/src/mappings/project/types';

import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import lodashIsMatchWith from 'lodash/isMatchWith';
import lodashMergeWith from 'lodash/mergeWith';
import lodashSet from 'lodash/set';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { fetcher } from '@core/hooks/useReadmeApi';
import type { APIErrorType } from '@core/types';

import { actionLog, createBoundedUseStore, isClient } from '../util';

import initialProjectState from './initial';

/**
 * Project store state object. This is mostly identical to our v2 API read
 * representation mapping.
 */
export type ProjectState = ReadProjectType['data'];
export type ProjectStateUpdate = EditProjectType;

/**
 * Project store actions, which performs mutations on our project state.
 */
interface ProjectStoreAction {
  /**
   * Accepts a list of form fields in "dot.notation.syntax" and returns a plain JS
   * object from the project store with these fields picked out. Current project
   * data is used by default, but initial data can be used instead by passing in
   * `true` to the second arg.
   * @example
   * getFormValues(['integrations.google.analytics'])
   * -> { integrations: { google: { analytics: 'G-12345' } } }
   *
   * // Get form values from initial data instead.
   * getFormValues(['integrations.google.analytics'], true)
   * -> { integrations: { google: { analytics: 'G-98765' } } }
   */
  getFormValues: (dotNotationFields: string[], initialData?: boolean) => ProjectStateUpdate;

  /**
   * Resets state back to the last "initialized" state. When `newState`
   * is provided, state is overridden entirely and re-initialized to
   * this point. Last "initialized" state is updated so that subsequent
   * calls will then reset back to this new state.
   */
  reset: (newState?: ProjectState) => void;

  /**
   * Clears the save error object.
   */
  resetSaveError: () => void;

  /**
   * Persists the project state to our DB via API. When `partialState` is
   * provided as an argument, its values are merged into the current state
   * before sending the API request. When no argument exists, the current state
   * is sent as is.
   * @example
   * save() - Commit save with the current state as is.
   * save({ appearance: { colors: { main: '#6cc' } } }) - Apply partial update, then commit save.
   */
  save: (partialState?: ProjectStateUpdate) => Promise<void>;

  /**
   * Performs a partial update on the project state.
   * @example
   * update({ data: { appearance: { hide_logo: true }}});
   */
  update: (partialState: ProjectStateUpdate) => void;
}

interface ProjectStoreState {
  /**
   * Contains the most current and up-to-date project state at any given moment.
   * This state is what's changed and acted upon when calling any action.
   */
  data: ProjectState;

  /**
   * Contains the project state when it was last initialized from a prior call
   * to actions `reset(newState)` or `save(partialState?)`. This is useful when
   * you need access to the project state before any updates have been made.
   */
  initialData: ProjectState;

  /**
   * Indicates whether the project store is fully hydrated with incoming data
   * either from our SSR props, API endpoint or some other call to reset() that
   * moves us out of our "default" starting state.
   */
  isReady: boolean;

  /**
   * Indicates whether a project save request is currently pending.
   */
  isSaving: boolean;

  /**
   * Error object that is set when a save request fails.
   */
  saveError: ErrorType | null;
}

export type ProjectStore = ProjectStoreAction & ProjectStoreState;

/**
 * Our initial project state. On the hub, this state will be immediately
 * overridden by SSR props and replace it. This provides some starting data
 * during testing and when running Styleguidist.
 */
const initialState = initialProjectState;

/**
 * Vanilla project store that contains `Project` data along with other
 * properties and actions that allow persisting updates from our SuperHub UI
 * forms. This store can be accessed and used anywhere. React components should
 * call `useProjectStore` instead.
 * @example
 * import { projectStore } from '@core/store';
 *
 * const isReady = projectStore.getState().isReady;
 */
export const projectStore = createStore<ProjectStore>()(
  devtools(
    immer((set, get) => {
      /**
       * Holds reference to the last initialized state. We store this to allow
       * project state to be reset back to this point.
       */
      let lastInitialState = initialState;

      return {
        data: lastInitialState,
        initialData: lastInitialState,
        isReady: false,
        isSaving: false,
        saveError: null,

        // Actions defined below in alphabetical order. If this list grows
        // long, consider splitting these out into smaller slices instead.
        // https://docs.pmnd.rs/zustand/guides/slices-pattern

        getFormValues: (dotNotationFields, initialData = false) => {
          const data = initialData ? get().initialData : get().data;
          return dotNotationFields.reduce<ProjectStateUpdate>((partial, dottedField) => {
            const value = lodashGet(data, dottedField) ?? undefined;
            if (value === undefined) return partial;
            return lodashSet(partial, dottedField, value);
          }, {});
        },

        reset: newState => {
          lastInitialState = newState ?? lastInitialState;
          set(
            state => {
              state.data = lastInitialState;
              state.initialData = lastInitialState;

              // When running on the server, we must avoid marking this store
              // as "ready" to ensure it continues receiving updates until it
              // gets initialized on the client's first render.
              if (isClient) {
                state.isReady = true;
              }
            },
            false,
            actionLog('reset', newState),
          );
        },

        resetSaveError: () => {
          if (!get().saveError) return;
          set(
            state => {
              state.saveError = null;
            },
            false,
            actionLog('resetSaveError'),
          );
        },

        save: async partialState => {
          const { data: origData, initialData: origInitialData } = get();

          set(
            state => {
              state.isSaving = true;

              // Optimistically update our store with incoming data so this can
              // be reflected in the UI while the request is in flight.
              if (partialState) {
                lodashMergeWith(state.data, partialState, (objValue, srcValue) => {
                  // We don't want to partially merge array values. Instead, we
                  // want to replace the entire array with the new one.
                  return Array.isArray(objValue) ? srcValue : undefined;
                });
              }
            },
            false,
            actionLog('save.pending'),
          );

          try {
            const subdomain = get().data.subdomain;
            const response = await fetcher<ReadProjectType>(`/${subdomain}/api-next/v2/projects/me`, {
              method: partialState ? 'PATCH' : 'POST',
              body: JSON.stringify(partialState ?? get().data),
            });

            lastInitialState = response.data;
            set(
              state => {
                state.data = lastInitialState;
                state.initialData = lastInitialState;
                state.isSaving = false;
                state.saveError = null;
              },
              false,
              actionLog('save.fulfilled', partialState),
            );
          } catch (error) {
            // Our fetcher stores the initial response data on `error.info`
            const { info } = error as APIErrorType;
            set(
              state => {
                state.data = origData;
                state.initialData = origInitialData;
                state.isSaving = false;
                state.saveError = info || null;
              },
              false,
              actionLog('save.rejected'),
            );
          }
        },

        update: partialState => {
          // Cancel update if state already contains changes inside partial.
          const isMatch = lodashIsMatchWith(get().data, partialState, (objValue, srcValue) => {
            // If the value is an array, we want to do a full comparison instead of a partial one.
            // This is so updates include additions/removals and re-ordering of array items.
            return Array.isArray(objValue) ? lodashIsEqual(objValue, srcValue) : undefined;
          });
          if (isMatch) return;

          set(
            state => {
              // To support partial updates, we use a "merge" util to help us.
              lodashMergeWith(state.data, partialState, (objValue, srcValue) => {
                // We don't want to partially merge array values. Instead, we want
                // to replace the entire array with the new one.
                return Array.isArray(objValue) ? srcValue : undefined;
              });
            },
            false,
            actionLog('update', partialState),
          );
        },
      };
    }),
    { name: 'ProjectStore' },
  ),
);

/**
 * Bound react hook to access our project store. Must be called within a React
 * component. To access the store outside of React, use `projectStore` instead.
 */
export const useProjectStore = createBoundedUseStore(projectStore);

export * from './ConnectProjectStoreToApi';
export * from './ConnectProjectStoreToDocument';
export * from './InitializeProjectStore';
