export const CUSTOM_BLOCK_NAME_EXISTS = 'CUSTOM_BLOCK_NAME_EXISTS';
export const INVALID_CUSTOM_BLOCK_NAME = 'INVALID_CUSTOM_BLOCK_NAME';

// Ensure the name includes at least one alphanumeric character
export const customBlockNameRegex: RegExp = /^(?=.*[a-zA-Z0-9]).*$/;

export const capitalize = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1);

export const addSpacesInBetweenWords = (phrase: string): string => phrase.replace(/([a-z])([A-Z])/, '$1 $2');

/**
 * A tiny utility function used to truncate strings and tack on ellipses (if we have room to do so).
 *
 */
export function truncateWithEllipses(input: string = '', length: number = 0): string {
  if (length <= 0) return '';
  if (length < 3) return input.substring(0, length);
  if (input.length <= length) return input;
  return `${input.substring(0, length - 3)}...`;
}
