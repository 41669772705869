import type { Operation } from 'oas/operation';

export const operationSupportsSecurityScheme = (operation: Operation, securityScheme: string[]): boolean => {
  return operation.getSecurityWithTypes(true).some(group => {
    if (!group) {
      return false;
    }
    return group.some(sec => {
      if (!sec) {
        return false;
      }
      return securityScheme.includes(sec.security._key);
    });
  });
};
