import React from 'react';

import CodeSnippet from '@ui/CodeSnippet';

interface RenderStoreDataProps {
  state: Record<string, unknown> & {
    reset?: () => void;
  };
}

/**
 * Renders a formatted code snippet of the provided store state that displays
 * all of its properties and actions. Only used in Storybook files to show
 * store data for documentation purposes.
 * @internal
 */
export const RenderStoreData = React.memo(function RenderStoreData({ state }: RenderStoreDataProps) {
  /**
   * Converts the existing `state` object into one that is serializable via
   * JSON.stringify, which would otherwise omit properties containing
   * non-serializable values, e.g. Functions.
   */
  const serializableState = Object.entries(state).reduce((obj, [key, value]) => {
    return {
      ...obj,
      [key]: typeof value === 'function' ? typeof value : value,
    };
  }, {});

  // Reset store state when unmounting so we always start with the same data.
  React.useEffect(() => {
    return () => {
      state.reset?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CodeSnippet
      code={JSON.stringify(serializableState, null, '  ')}
      language="json"
      options={{ foldGutter: true, readOnly: true }}
      style={{
        border: '1px solid var(--color-border-default)',
        fontSize: '14px',
        maxHeight: '500px',
        overflow: 'auto',
      }}
    />
  );
});
