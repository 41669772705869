import type { APIDesignerStore } from '..';
import type { CODE_SAMPLES, Extensions } from 'oas/extensions';
import type { StateCreator } from 'zustand';

import { actionLog } from '@core/store/util';

export interface RequestExampleEditorSliceState {
  /**
   * The custom code samples for the current operation.
   */
  codeSamples: Extensions[typeof CODE_SAMPLES];

  /**
   * Determines if the editor is in the initial state with no examples
   * or a custom state where the user is writing custom examples.
   */
  editorState: 'custom' | 'initial';

  /**
   * Indicates whether the given operation has custom examples or not.
   */
  hasExamples: boolean;

  /**
   * The index of the currently selected example.
   */
  selectedExample: number;
}

export interface RequestExampleEditorSliceActions {
  /**
   * Adds a custom request example to the current operation.
   */
  addCustomRequestExample: () => void;

  /**
   * Deletes the currently selected example.
   */
  deleteRequestExample: () => void;

  initialize: () => void;

  /**
   * Sets the editor state to the provided value.
   */
  setEditorState: (editorState: RequestExampleEditorSliceState['editorState']) => void;

  /**
   * Updates the language of the currently selected example.
   */
  setLanguage: (language: string) => void;

  /**
   * Updates the index of the currently selected example.
   */
  setSelectedExample: (exampleNumber: number) => void;

  /**
   * Updates the name of the currently selected example.
   */
  updateExampleName: (exampleName: string) => void;

  /**
   * Updates the `samples-languages` array for the current operation.
   * It rebuilds the array from the other code samples in the operation.
   * For parity with the old editor (and for the UX, to be clear) we're making this match
   * the defined code samples in the Designer.
   *
   * @see {@link https://docs.readme.com/main/docs/openapi-extensions#code-sample-languages}
   */
  updateSamplesLanguages: () => void;

  /**
   * Updates the code of the current snippet.
   */
  updateSnippet: (editor, data, value: string) => void;
}

export interface RequestExampleEditorSlice {
  /**
   * State slice containing fields and actions that pertain to the Request Example Editor
   */
  requestExampleEditor: RequestExampleEditorSliceActions & RequestExampleEditorSliceState;
}

export const initialState: RequestExampleEditorSliceState = {
  codeSamples: [],
  editorState: 'initial',
  hasExamples: false,
  selectedExample: 0,
};

/**
 * Request Example Editor state slice containing fields related to the Manual Editor — Request Example Editor.
 */
export const createRequestExampleEditorSlice: StateCreator<
  APIDesignerStore & RequestExampleEditorSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  RequestExampleEditorSlice
> = (set, get) => ({
  requestExampleEditor: {
    ...initialState,

    addCustomRequestExample: () => {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();

      const newSnippet = {
        code: '',
        language: 'shell',
        name: '',
      };

      const newOperation = structuredClone(operation);

      if (!get().requestExampleEditor.hasExamples) {
        // If we get to this point, an OAS and operation should exist, so we can safely
        // assume that we can find an operation for the given path and method.
        newOperation!['x-readme'] = {
          'code-samples': [newSnippet],
        };
      } else if (get().requestExampleEditor.hasExamples) {
        if (newOperation!['x-code-samples']) {
          (newOperation!['x-code-samples'] as Extensions[typeof CODE_SAMPLES]).push(newSnippet);
        }

        if (newOperation!['x-readme']?.['code-samples']) {
          newOperation!['x-readme']['code-samples'].push(newSnippet);
        }
      }

      const codeSamples = newOperation?.['x-readme']?.['code-samples'] || operation?.['x-code-samples'];
      const hasExamples = !!codeSamples?.length;

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          state.requestExampleEditor.codeSamples = codeSamples || [];
          state.requestExampleEditor.editorState = 'custom';
          state.requestExampleEditor.hasExamples = hasExamples;
          state.requestExampleEditor.selectedExample = codeSamples ? codeSamples!.length - 1 : 0;
        },
        false,
        actionLog('adding request example', { newOperation }),
      );

      get().requestExampleEditor.updateSamplesLanguages();
    },

    deleteRequestExample() {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();

      const newOperation = structuredClone(operation);

      if (newOperation!['x-code-samples']) {
        if ((newOperation!['x-code-samples'] as Extensions[typeof CODE_SAMPLES]).length === 1) {
          delete newOperation!['x-code-samples'];
          get().requestExampleEditor.setEditorState('initial');
        } else {
          (newOperation!['x-code-samples'] as Extensions[typeof CODE_SAMPLES]).splice(
            get().requestExampleEditor.selectedExample,
            1,
          );
        }
      }

      if (newOperation!['x-readme']?.['code-samples']) {
        if (newOperation!['x-readme']['code-samples'].length === 1) {
          delete newOperation!['x-readme']['code-samples'];
          get().requestExampleEditor.setEditorState('initial');
        } else {
          newOperation!['x-readme']['code-samples'].splice(get().requestExampleEditor.selectedExample, 1);
        }
      }

      // Ensure we update the selected example if we're deleting the last example in the list
      if (
        get().requestExampleEditor.selectedExample === get().requestExampleEditor.codeSamples.length - 1 &&
        get().requestExampleEditor.codeSamples.length > 1
      ) {
        get().requestExampleEditor.setSelectedExample(get().requestExampleEditor.selectedExample - 1);
      }

      const codeSamples = newOperation?.['x-readme']?.['code-samples'] || operation?.['x-code-samples'];
      const hasExamples = !!codeSamples?.length;

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          state.requestExampleEditor.codeSamples = codeSamples || [];
          state.requestExampleEditor.hasExamples = hasExamples;
        },
        false,
        actionLog('deleting request example', { newOperation }),
      );

      get().requestExampleEditor.updateSamplesLanguages();
    },

    initialize: () => {
      const { operation } = get().getCurrentOperation();

      const codeSamples = operation?.['x-readme']?.['code-samples'] || operation?.['x-code-samples'];

      const hasExamples = !!codeSamples?.length;

      set(
        state => {
          state.requestExampleEditor.codeSamples = codeSamples || [];
          state.requestExampleEditor.editorState = hasExamples ? 'custom' : 'initial';
          state.requestExampleEditor.hasExamples = hasExamples;
        },
        false,
        actionLog('initialize request example editor slice', {}),
      );
    },

    setEditorState(editorState: RequestExampleEditorSliceState['editorState']) {
      set(
        state => {
          state.requestExampleEditor.editorState = editorState;
        },
        false,
        actionLog('setting request editor state', { editorState }),
      );
    },

    setLanguage(language: string) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();

      const newOperation = structuredClone(operation);

      if (newOperation!['x-code-samples']) {
        newOperation!['x-code-samples'][get().requestExampleEditor.selectedExample].language = language;
      }

      if (newOperation!['x-readme']?.['code-samples']) {
        newOperation!['x-readme']['code-samples'][get().requestExampleEditor.selectedExample].language = language;
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
          const codeSamples = newOperation?.['x-readme']?.['code-samples'] || operation?.['x-code-samples'];
          state.requestExampleEditor.codeSamples = codeSamples || [];
        },
        false,
        actionLog('setLanguage', { language }),
      );

      get().requestExampleEditor.updateSamplesLanguages();
    },

    setSelectedExample(exampleNumber: number) {
      set(
        state => {
          state.requestExampleEditor.selectedExample = exampleNumber;
        },
        false,
        actionLog('setting selected request example', { exampleNumber }),
      );
    },

    updateExampleName(exampleName: string) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();

      const newOperation = structuredClone(operation);

      if (newOperation!['x-code-samples']) {
        newOperation!['x-code-samples'][get().requestExampleEditor.selectedExample].name = exampleName;
      }

      if (newOperation!['x-readme']?.['code-samples']) {
        newOperation!['x-readme']['code-samples'][get().requestExampleEditor.selectedExample].name = exampleName;
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
        },
        false,
        actionLog('updating request example name', { exampleName }),
      );
    },

    updateSamplesLanguages() {
      const generateSamplesLanguages = (codeSamples: Extensions[typeof CODE_SAMPLES]) => {
        return Array.from(new Set(codeSamples.map(sample => sample.language)));
      };

      const { operation, currentPath, currentMethod } = get().getCurrentOperation();

      if (!operation) {
        throw new Error('Operation not found');
      }

      const newOperation = structuredClone(operation);

      if (!newOperation['x-readme']) {
        newOperation!['x-readme'] = {
          'samples-languages': [],
        };
      }

      if (newOperation['x-code-samples']) {
        if (!Array.isArray(newOperation!['x-code-samples'])) {
          return;
        }
        const samplesLanguages = generateSamplesLanguages(newOperation['x-code-samples']);
        (newOperation['x-readme'] as Extensions)['samples-languages'] = samplesLanguages;
      } else if (newOperation['x-readme']?.['code-samples']) {
        const samplesLanguages = generateSamplesLanguages(newOperation['x-readme']['code-samples']);
        newOperation['x-readme']['samples-languages'] = samplesLanguages;
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
        },
        false,
        actionLog('updating samples languages'),
      );
    },

    updateSnippet(_editor, _data, value: string) {
      const { operation, currentPath, currentMethod } = get().getCurrentOperation();

      const newOperation = structuredClone(operation);

      if (newOperation!['x-code-samples']) {
        newOperation!['x-code-samples'][get().requestExampleEditor.selectedExample].code = value;
      }

      if (newOperation!['x-readme']?.['code-samples']) {
        newOperation!['x-readme']['code-samples'][get().requestExampleEditor.selectedExample].code = value;
      }

      set(
        state => {
          state.apiObject!.schema!.paths![currentPath]![currentMethod] = newOperation;
        },
        false,
        actionLog('updating request snippet', { value }),
      );
    },
  },
});
