import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { PageDocument } from '@readme/backend/models/page/types';

/**
 * Git vs Legacy (Mongo) sidebar page type for the Hub's sidebar in view mode.
 */
export type SidebarPage = GitSidebarPage | PageDocument;

/**
 * Sidebar category type for the Hub's sidebar in view mode. Rather than create
 * a union between Git and Mongo categories, we intentionally create the unioned
 * interface ourselves for easier usage.
 */
export interface SidebarCategory {
  _id?: string;
  pages: SidebarPage[];
  slug?: string;
  title: string;
  uri?: string;
}

/**
 * Normalize for children pages between Git and Mongo pages.
 */
export function getChildrenPages(page: SidebarPage): SidebarPage[] {
  if ('pages' in page) {
    return page.pages || [];
  }
  return page.children || [];
}

/**
 * Check if a sidebar page has children pages. By default, returns `true` only
 * if visible pages exist. Use the second arg to override this behavior and
 * check if either visible or hidden pages exist.
 * @example
 * ```ts
 * const hasVisibleChildren = hasChildrenPages(page);
 * const hasAnyChildren = hasChildrenPages(page, true);
 * ```
 */
export function hasChildrenPages(page: SidebarPage, includeHidden: boolean = false): boolean {
  const pages = includeHidden ? getChildrenPages(page) : getChildrenPages(page).filter(child => !child.hidden);
  return pages.length > 0;
}

/**
 * Detect empty parent pages are pages with no body content that contain at least a single child.
 */
export function isEmptyParentPage(page: SidebarPage): boolean {
  return (
    ('isReference' in page && page.isReference
      ? // Only pages that are neither endpoints nor realtime pages should be considered empty
        page.type !== 'endpoint' && page.pageType !== 'RealtimePage'
      : page.type === 'basic') &&
    page.isBodyEmpty &&
    hasChildrenPages(page)
  );
}

/**
 * Recursively finds and returns the first child page that is not empty.
 */
export function findFirstContentfulChild<Page extends SidebarPage>(children: Page[]): Page | null {
  if (!children.length) return null;

  const firstChild = children[0];
  const firstChildPages = 'pages' in firstChild ? firstChild.pages : firstChild.children;
  if (firstChild.isBodyEmpty && firstChildPages?.length) {
    return findFirstContentfulChild(firstChildPages);
  }
  return firstChild;
}

/**
 * Recursively iterates over each sidebar page and finds the one that matches
 * the provided slug. Returns `null` if page was not found.
 * @example
 * ```ts
 * const page = findSidebarPageBySlug(sidebar, slug);
 * ```
 */
export function findSidebarPageBySlug(
  entities: (GitSidebarCategory | GitSidebarPage)[],
  slug: string,
): GitSidebarPage | null {
  for (let n = 0; n < entities.length; n += 1) {
    const entity = entities[n];
    if ('slug' in entity && entity.slug === slug) return entity;
    if ('pages' in entity) {
      const result = findSidebarPageBySlug(entity.pages, slug);
      if (result) return result;
    }
  }
  return null;
}

function flattenPage(page: GitSidebarPage): GitSidebarPage[] {
  // if this page has no subpages, we've reached the end! just return it
  if (!page.pages.length) {
    return [page];
  }
  // otherwise, recursively flatten each of its subpages
  return [page, ...page.pages.flatMap(flattenPage)];
}

/**
 * Returns the first page in the sidebar. Excludes `hidden` and `link` pages by
 * default but can be overridden to include them using the `options` flags.
 * Returns `null` if not found.
 * @example
 * ```ts
 * const firstPage = findSidebarFirstPage(sidebar);
 * const firstPublicHiddenOrLinkPage = findSidebarFirstPage(sidebar, {
 *   includeHidden: true,
 *   includeLinks: true,
 * })
 * const firstEndpointPage = findSidebarFirstPage(sidebar, {
 *   pageTypes: ['endpoint'],
 * })
 * ```
 */
export function findSidebarFirstPage(
  sidebar: GitSidebarCategory[],
  options: {
    /** When true, includes hidden pages in the result. */
    includeHidden?: boolean;
    /** When true, includes link page types in the result. */
    includeLinks?: boolean;
    /** When non-empty, includes pages only of the provided types. */
    pageTypes?: GitSidebarPage['type'][];
  } = {},
): GitSidebarPage | null {
  for (let n = 0; n < sidebar.length; n += 1) {
    const category = sidebar[n];

    /**
     * If the category is the References API Config and all pages are hidden, skip to the next category.
     * This allows us to show an empty state in references when there are no user-defined pages.
     */
    const isEmptyAPIConfigCategory =
      category.pages.length && category.pages[0].type === 'api_config' && category.pages.every(page => page.hidden);

    if (!isEmptyAPIConfigCategory) {
      // category pages have depth (e.g. page > childSubpage >
      // grandchildSubpage). flatten all of those pages into a single list that
      // we can search through
      const flatPages = category.pages.flatMap<GitSidebarPage>(flattenPage);

      for (let k = 0; k < flatPages.length; k += 1) {
        const page = flatPages[k];
        const linkTest = options.includeLinks ? true : page.type !== 'link';
        const hiddenTest = options.includeHidden ? true : !page.hidden;
        const pageTypesTest = options.pageTypes?.length ? options.pageTypes.includes(page.type) : true;
        if (linkTest && hiddenTest && pageTypesTest) {
          return page;
        }
      }
    }
  }
  return null;
}

export * from './rdmd';
