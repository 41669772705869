export const getGroupNameById = (
  groups: Record<string, unknown>[] | undefined,
  groupId: string | undefined,
): string | false => {
  // We need to remap the incoming group with the groups name so we can pick out the auth keys
  // in `getAuth`.
  if (!groups || !groupId) {
    return false;
  }

  const relevantKey = groups.find(key => {
    return Object.keys(key).some(keyName => key[keyName] === groupId);
  });

  if (relevantKey) {
    return relevantKey.name as string;
  }

  return false;
};
