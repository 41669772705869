export enum Column {
  APIKey = 'apiKey',
  APIRequests = 'apiRequests',
  Comment = 'comment',
  Company = 'company',
  CreatedAt = 'createdAt',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Time = 'createdAt',
  Email = 'email',
  EventName = 'eventName',
  GroupEmail = 'groupEmail',
  GroupId = 'groupId',
  GroupLabel = 'groupLabel',
  Id = 'id',
  Label = 'label',
  Language = 'language',
  LastRequest = 'lastRequest',
  Link = 'link',
  Method = 'method',
  OperationId = 'operationId',
  Page = 'page',
  Path = 'path',
  RecentRequest = 'recentRequest',
  SearchTerm = 'searchTerm',
  Status = 'status',
  Trend = 'trend',
  Url = 'url',
  User = 'user',
  UserAgent = 'useragent',
  Version = 'version',
  Vote = 'vote',
}

export enum MetricsApiLogsSupportedLanguagesType {
  DotNet = 'dotnet',
  Node = 'node',
  PHP = 'php',
  Python = 'python',
  Ruby = 'ruby',
}

export enum MetricsApiKeysSupportedLanguagesType {
  // Amazon API Gateway is unique in that it has sublanguage options, so we treat 'aws' as the base language here
  AWS = 'aws',
  CSharp = 'csharp',
  Node = 'node',
  PHP = 'php',
  Python = 'python',
  Ruby = 'ruby',
}

export enum MetricsSearchType {
  ApiCalls = 'apiCalls',
  ApiErrors = 'apiErrors',
  ApiKeyInsightCalls = 'apiKeyInsightCalls',
  ApiKeys = 'apiKeys',
  NewUsers = 'newUsers',
  PageQuality = 'pageQuality',
  PageViews = 'pageViews',
  Search = 'search',
  TopEndpoints = 'topEndpoints',
  Users = 'users',
}

export enum MetricsSearchSectionType {
  ApiMetrics = 'apiMetrics',
  Developers = 'developers',
  DocMetrics = 'docMetrics',
  Users = 'users',
}

export enum MyDevelopersSearchSectionType {
  ApiKeys = 'apiKeys',
  Requests = 'requests',
  Users = 'users',
}

export enum MyDevelopersSubrouteType {
  Demo = 'demo',
  Key = 'key',
  Segment = 'segment',
  Setup = 'setup',
  User = 'user',
}

export enum TableType {
  APIKeyInsights = 'APIKeyInsights',
  RecentRequests = 'RecentRequests',
}
