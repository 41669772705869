export enum UserPermission {
  None = '',
  Admin = 'admin',
  ReadOnly = 'readonly',
}

export interface ProjectPermission {
  _id?: string;
  fromGroup?: boolean;
  groupName?: string;
  project: any;
  userType: TUserPermission;
}

// This type allows consumers to pass in the literal string value of the enum,
// mostly to support legacy code
export const TUserPermission = {
  Admin: 'admin',
  None: '',
  ReadOnly: 'readonly',
} as const;
export type TUserPermission = (typeof TUserPermission)[keyof typeof TUserPermission] | UserPermission;

type PermissionVerbage = 'Admin' | 'Group Admin' | 'Group Viewer' | 'No Access' | 'Viewer';

export const verbageToTypes: Record<PermissionVerbage, ProjectPermission['userType']> = {
  'No Access': '',
  'Group Viewer': 'readonly',
  Viewer: 'readonly',
  'Group Admin': 'admin',
  Admin: 'admin',
};

export const groupTypesToVerbage: Record<TUserPermission, PermissionVerbage> = {
  [TUserPermission.None]: 'No Access',
  [TUserPermission.ReadOnly]: 'Group Viewer',
  [TUserPermission.Admin]: 'Group Admin',
};

export const typesToVerbage: Record<TUserPermission, PermissionVerbage> = {
  [TUserPermission.None]: 'No Access',
  [TUserPermission.ReadOnly]: 'Viewer',
  [TUserPermission.Admin]: 'Admin',
};

export enum LoginType {
  CUSTOM_JWT = 'custom-jwt',
  OAUTH = 'oauth',
  README = 'readme',
  SAML = 'saml',
}

/**
 * Feature flag for rendering OAuth Flows in the API Explorer.
 *
 * If `legacy-enabled`, the project contains an OAuth URL, and an "Authorize" button should be displayed above the
 * OAuth input.
 *
 * If `enabled`, the OAuth flows UI should be displayed.
 *
 * If `false`, no OAuth UI should be displayed.
 */
export type OAuthFeatureFlag = 'enabled' | 'legacy-enabled' | false;
