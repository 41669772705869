import get from 'lodash/get'; // eslint-disable-line you-dont-need-lodash-underscore/get

/**
 * Converts an array of object into a hash representation
 */
export function toHashMap<T, K extends number | string | symbol, V = T>(
  array: T[],
  key: string,
  valueKey?: string,
): Record<K, V extends T ? T : V> {
  return array.reduce(
    (acc, current) => {
      const k = get(current, key) as K;
      if (k === undefined) {
        return acc;
      }

      acc[k] = valueKey ? get(current, valueKey) : current;
      // Handle non-existed keys or values
      if (k === undefined || acc[k] === undefined) delete acc[k];
      return acc;
    },
    {} as Record<K, V extends T ? T : V>,
  );
}

/**
 * Returns a new array with an item removed at a specified index.
 *
 */
export function removeAtIndex<T = unknown>(arr: T[], idx: number): T[] {
  return arr.slice(0, idx).concat(arr.slice(idx + 1));
}

/**
 * Takes an array and an item to remove, returns a new array with that item removed.
 */
export function removeFromArray<T>(arr: T[], item: T): T[] {
  const idx = arr.indexOf(item);
  if (idx < 0) return arr;

  return removeAtIndex(arr, idx);
}

/**
 * Takes an array and an item to add, returns a new array with that item added.
 *
 */
export function addToArray<T = unknown>(arr: T[], item: T, idx: number): T[] {
  if (idx == null || idx < 0) return arr;

  const next = [...arr];
  // If the insertion index is too big, add it to the end
  const isOutOfBounds = idx >= next.length;
  next.splice(isOutOfBounds ? next.length : idx, 0, item);

  return next;
}

/**
 * Takes an array and an item to move, returns a new array with that item moved.
 *
 */
export function moveInArray<T>(arr: T[], item: T, idx: number): T[] {
  return addToArray(removeFromArray(arr, item), item, idx);
}

/**
 * Takes an array and an item to replace, returns a new array with that item replaced.
 *
 */
export function replaceAtIndex<T>(arr: T[], item: T, idx: number): T[] {
  return addToArray(removeAtIndex(arr, idx), item, idx);
}

export function isSequential(arr: number[]): boolean {
  return arr.every((num, i) => i === 0 || num === arr[i - 1] + 1);
}
