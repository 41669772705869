import type { SuperHubStore } from '..';
import type {
  CreateVersionType,
  ReadVersionCollectionType,
  ReadVersionType,
  UpdateVersionType,
} from '@readme/api/src/mappings/version/types';
import type { SetOptional, WritableDeep } from 'type-fest';
import type { StateCreator } from 'zustand';

import produce from 'immer';
import { mutate } from 'swr';

import type useReadmeApi from '@core/hooks/useReadmeApi';
import { fetcher } from '@core/hooks/useReadmeApi';
import { projectStore } from '@core/store/Project';
import { actionLog, isClient } from '@core/store/util';
import type { APIErrorType } from '@core/types';

import { createDefaultVersion } from './defaults';

interface SuperHubVersionSliceState {
  /**
   * Current project version that is in view, e.g. `1.0`.
   */
  currentVersion: string;

  /**
   * Paginated collection data containing a project's git versions.
   */
  data: ReadVersionCollectionType;

  /**
   * Indicates whether we should be in "creation" mode by being assigned a newly
   * forked version or `null`. Use `updateEphemeralVersion()` to update this.
   */
  ephemeralVersion: CreateVersionType | null;

  /**
   * Contains error information that occurred during hydration or updates.
   * @todo Make this a more specific type that matches the error we expect to
   * receive from the `/versions` endpoint.
   * @link https://linear.app/readme-io/issue/RM-11921/handle-errors-coming-back-from-our-api-endpoints
   */
  error: APIErrorType | null;

  /**
   * Whether versions data is currently being fetched via API.
   */
  isLoading: boolean;

  /**
   * Indicates this slice has been initialized on both server and client. We use
   * this to differentiate how the state should update during SSR vs CSR. On the
   * server, the state should never prevent updates or retain previously
   * existing data. This is only safe to do once this flag is flipped to `true`.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Holds reference to the SWR request key that is used to fetch versions data
   * from our API endpoint. This key is used by SWR's `mutate()` function to
   * update the SWR cache after a mutation request returns successful.
   * @link https://swr.vercel.app/docs/mutation
   * @example
   * ```ts
   * ['/cats/api-next/v2/versions', {}]
   * ```
   */
  swrKey: ReturnType<typeof useReadmeApi>['swrKey'];
}

interface SuperHubVersionSliceAction {
  /**
   * Creates a new version that is forked from another version.
   */
  createVersion: (newVersion: CreateVersionType) => Promise<ReadVersionType>;

  /**
   * Deletes a version.
   */
  deleteVersion: (name: ReadVersionType['data']['name']) => Promise<void>;

  /**
   * Updates this state slice with initial data, loading state, error, etc.
   */
  initialize: (
    payload: SetOptional<Pick<SuperHubVersionSliceState, 'data' | 'error' | 'isLoading' | 'swrKey'>, 'data' | 'swrKey'>,
  ) => void;

  /**
   * Renames an existing version to a different name.
   */
  renameVersion: (name: ReadVersionType['data']['name'], newName: string) => Promise<ReadVersionType>;

  /**
   * Revalidates versions data by refetching data from our API endpoint.
   */
  revalidate: () => Promise<ReadVersionCollectionType | undefined>;

  /**
   * Updates `ephemeralVersion` to enter "creation" mode by assigning a new
   * version that's been forked from the provided version or `currentVersion`.
   * Passing in `null` clears the field and exits "creation" mode.
   * @example
   * ```ts
   * superHubStore.versions.updateEphemeralVersion();      // Fork from current version
   * superHubStore.versions.updateEphemeralVersion('2.0'); // Fork from version 2.0
   * superHubStore.versions.updateEphemeralVersion(null);  // Clear ephemeral version
   * ```
   */
  updateEphemeralVersion: (baseVersionName?: ReadVersionType['data']['name'] | null) => void;

  /**
   * Updates an existing version. Note that if you want to update the name of
   * the version, the `renameVersion` action should be used instead because it
   * has rename-specific fulfilment logic.
   */
  updateVersion: (
    name: ReadVersionType['data']['name'],
    updatedVersion: Omit<UpdateVersionType, 'name'>,
  ) => Promise<ReadVersionType>;
}

export interface SuperHubVersionSlice {
  /**
   * State slice containing git versions data for the current project and all
   * actions that can be performed against it.
   */
  versions: SuperHubVersionSliceAction & SuperHubVersionSliceState;
}

const initialState: SuperHubVersionSliceState = {
  currentVersion: '',
  data: {
    data: [],
    per_page: 0,
    total: 0,
    page: 0,
    paging: {
      next: null,
      previous: null,
      first: null,
      last: null,
    },
  },
  ephemeralVersion: null,
  error: null,
  isLoading: false,
  isReady: false,
  swrKey: null,
};

/**
 * Versions state slice containing all things related to project versions.
 */
export const createSuperHubVersionSlice: StateCreator<
  SuperHubStore & SuperHubVersionSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  SuperHubVersionSlice
> = (set, get) => ({
  versions: {
    ...initialState,

    createVersion: async newVersion => {
      set(
        state => {
          state.versions.data?.data.push({
            base: newVersion.base,
            name: newVersion.name || '',
            display_name: newVersion.display_name || null,
            release_stage: newVersion.release_stage || 'release',
            source: newVersion.base ? 'readme' : 'bidi',
            state: newVersion.state || 'current',
            updated_at: new Date().toISOString(),
            uri: `/versions/${newVersion.name}/PENDING`,
            privacy: { view: newVersion.privacy?.view || 'hidden' },
            git: {
              latest_commit: {
                created_at: null,
                hash: null,
              },
              branch_ref: null,
            },
          });
          state.versions.data.total += 1;
        },
        false,
        actionLog('versions.createVersion', newVersion),
      );

      const request = fetcher<ReadVersionType>(`${get().apiBaseUrlWithoutVersion}/versions`, {
        method: 'POST',
        body: JSON.stringify(newVersion),
      });

      await mutate<ReadVersionCollectionType>(
        get().versions.swrKey,
        request.then(({ data }) =>
          produce(get().versions.data, draft => {
            draft.data[draft.data.length - 1] = { ...data };
          }),
        ),
        { revalidate: true },
      );

      // Clear ephemeral version after successful creation.
      get().versions.updateEphemeralVersion(null);

      return request;
    },

    deleteVersion: async name => {
      const request = fetcher<void>(`${get().apiBaseUrlWithoutVersion}/versions/${name}`, {
        method: 'DELETE',
      });

      await mutate<ReadVersionCollectionType>(
        get().versions.swrKey,
        request.then(() =>
          produce(get().versions.data, draft => {
            const indexToRemove = draft.data.findIndex(v => v.name === name);
            if (indexToRemove > -1) {
              draft.data.splice(indexToRemove, 1);
              draft.total -= 1;
            }
          }),
        ),
        { revalidate: false },
      );
      return request;
    },

    initialize: ({ data, error, isLoading, swrKey }) => {
      const nextData = {
        data: data || get().versions.data,
        error,
        isLoading,
      };

      // Only continue with a state update if there are changed values. This
      // quiets down the redux devtools action logs to only contain actions
      // that contain differences.
      const hasChanges = Object.entries(nextData).some(([key, value]) => {
        return JSON.stringify(value) !== JSON.stringify(get().versions[key]);
      });
      if (!hasChanges) return;

      set(
        state => {
          const writableSwrKey = swrKey as WritableDeep<typeof swrKey>;
          const writableError = error as WritableDeep<typeof error>;
          state.versions = {
            ...state.versions,
            ...nextData,
            error: writableError,
            swrKey: writableSwrKey ?? null,
          };

          // When running on the server, we must avoid marking this store as
          // "ready" to ensure it continues receiving updates until it gets
          // initialized on the client's first render.
          state.versions.isReady = isClient;
        },
        false,
        actionLog('versions.initialize', { data, error, isLoading, swrKey }),
      );
    },

    renameVersion: async (name, newName) => {
      const request = fetcher<ReadVersionType>(`${get().apiBaseUrlWithoutVersion}/versions/${name}`, {
        method: 'PATCH',
        body: JSON.stringify({ name: newName }),
      });

      await mutate<ReadVersionCollectionType>(
        get().versions.swrKey,
        request.then(({ data }) =>
          produce(get().versions.data, draft => {
            const target = draft.data.find(v => v.name === name);
            if (target) {
              target.name = data.name;
              target.uri = data.uri;
            }
          }),
        ),
        { revalidate: true },
      );

      // update the `default_version` of the project store if that's the
      // version that was renamed
      const renamedDefaultVersion = projectStore.getState().data.default_version.name === name;
      if (renamedDefaultVersion) {
        projectStore.getState().update({ default_version: { name: newName } });
      }

      // update the `currentVersion` of this slice if that's the version that
      // was renamed
      const renamedCurrentVersion = get().versions.currentVersion === name;
      if (renamedCurrentVersion) {
        set(
          state => {
            state.versions.currentVersion = newName;
          },
          false,
          actionLog('versions.renameVersion.fulfilled', { name, newName }),
        );
      }

      return request;
    },

    revalidate: () => {
      return mutate<ReadVersionCollectionType>(get().versions.swrKey);
    },

    updateEphemeralVersion: baseVersionName => {
      set(
        state => {
          state.versions.ephemeralVersion =
            baseVersionName === null
              ? null
              : createDefaultVersion({
                  base: baseVersionName || state.versions.currentVersion,
                });
        },
        false,
        actionLog('versions.updateEphemeralVersion', baseVersionName),
      );
    },

    updateVersion: async (name, updatedVersion) => {
      const updatedToDefaultVersion = updatedVersion.privacy?.view === 'default';

      if ('name' in updatedVersion) {
        throw new Error(
          'Version `name` was provided to the `updateVersion` action. Use the `renameVersion` action instead.',
        );
      }

      set(
        state => {
          state.versions.data.data.forEach(version => {
            // if we updated the version to be the default, find the previous
            // default version and unset it back to public
            if (updatedToDefaultVersion && version.privacy.view === 'default') {
              version.privacy.view = 'public';
            }

            // always modify the targeted version to have the new updated data
            if (version.name === name) {
              Object.assign(version, updatedVersion, {
                privacy: {
                  view: updatedVersion.privacy?.view || version.privacy.view,
                },
              });
            }
          });
        },
        false,
        actionLog('versions.updateVersion', { name, updatedVersion }),
      );

      const request = fetcher<ReadVersionType>(`${get().apiBaseUrlWithoutVersion}/versions/${name}`, {
        method: 'PATCH',
        body: JSON.stringify(updatedVersion),
      });

      await mutate<ReadVersionCollectionType>(
        get().versions.swrKey,
        request.then(({ data }) =>
          produce(get().versions.data, draft => {
            const targetIndex = draft.data.findIndex(v => v.name === name);
            if (targetIndex > -1 && data) {
              draft.data[targetIndex] = data;
            }
          }),
        ),
        { revalidate: false },
      );

      // if we updated the version to be the default, update the
      // `default_version` of the project store
      if (updatedToDefaultVersion) {
        projectStore.getState().update({ default_version: { name } });
      }

      return request;
    },
  },
});

export * from './Initialize';
