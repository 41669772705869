import type { ProjectPermission } from '../types/auth';
import type { Hub2UserDocument } from '@readme/backend/models/hub2user/types';
import type { ProjectDocument } from '@readme/backend/models/project/types';
import type { UserDocument } from '@readme/backend/models/user/types';

import { toHashMap } from '../utils/arrays';

/**
 * Merge two permissions array together. We ensure that if there are duplicates, the source
 * permissions are not altered, even if the target permission has a higher permission.
 */
export function syncPermissions(source: ProjectPermission[], target: ProjectPermission[]): ProjectPermission[] {
  const sourceHash = toHashMap(source, 'project');
  const targetHash = toHashMap(target, 'project');

  return Object.values(Object.assign({}, targetHash, sourceHash));
}

const parsePermissions = (item: Hub2UserDocument | UserDocument) => {
  const permissions = item.permissions;
  const groups = item.groups;

  if (groups?.length) {
    groups.forEach(group => {
      // Sometimes this comes in as a mongo document, so we can't use a spread operator here in that case
      // eslint-disable-next-line no-param-reassign
      group.fromGroup = true;
    });
    // Sync existing user permissions with our group permissions, but prefer the group ones
    return syncPermissions(groups, permissions);
  }
  // If there's not groups, just return the normal permissions
  return permissions;
};

/**
 * Takes in a user or array of users and returns that user(s) permissions
 * SAML group permissions are preferred over custom permissions on sync
 *
 * FIXME - Always deduplicate project permissions here using logic in `@readme/backend/utils/auth/permissions/deduplicatePermissions`
 */
export function getPermissions<T = ProjectPermission[] | Record<UserDocument['_id'], ProjectPermission[]>[]>(
  source: (Hub2UserDocument | UserDocument)[] | Hub2UserDocument | UserDocument,
): T {
  if (!source) return [] as T;

  // For an array, we create an object of key/value pairs
  // The key for each user is their id
  if (Array.isArray(source)) {
    const usersMap = source.map(user => {
      const perms = parsePermissions(user);
      return { [user._id]: perms };
    });
    return Object.assign({}, ...usersMap);
  }

  // If it's not an array of users, just return the permissions array for that single user
  return parsePermissions(source) as T;
}

function getPermissionsHash(user: UserDocument): Record<string, ProjectPermission['userType']> {
  const permissions = getPermissions(user) as ProjectPermission[];
  return permissions.reduce(
    (
      acc: Record<string, ProjectPermission['userType']>,
      current: { project: string; userType: ProjectPermission['userType'] },
    ) => {
      acc[current.project] = current.userType;
      return acc;
    },
    {},
  );
}

/**
 * Isomorphic function to get the UserType for the Member for UI DISPLAY PURPOSES ONLY. For this to be used correctly, the user must already be a member
 * of the organization. Otherwise, it will throw an error.
 */
export function getUserTypeForUI(
  project: ProjectDocument,
  user: UserDocument,
): 'Custom' | 'Group Admin' | 'Group Owner' | 'Group Viewer' {
  const ownerId = project.owner._id ? project.owner._id.toString() : project.owner.toString();
  // invites don't have _ids, and wouldn't be the owner anyway
  if (ownerId === (user._id && user._id.toString())) return 'Group Owner';

  const isParent = project.childrenProjects.length > 0;
  const userPermHash = getPermissionsHash(user);

  // is member if userPermissions contains parent perm and is readonly
  // is admin if userPermissions contains parent perm and is admin
  if (userPermHash[project._id]) {
    return userPermHash[project._id] === 'readonly' ? 'Group Viewer' : 'Group Admin';
  }

  // if someProjects if userPermissions contains at least one permission for a child
  if (isParent) return 'Custom';
  // return project.childrenProjects.some(child => userPermHash[child._id]) ? 'Custom';

  throw new Error('This user doesnt belong to this organization');
}
