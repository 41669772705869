/** ***** BUSINESS LOGIC for Developer Dashboard Billing ******* */
export const METRICS_BASE = {
  COST: 100,
  LOGS: 5e6,
};
export const METRICS_OVERAGE = {
  COST: 10,
  LOGS: 1e6,
};
export const METRICS_CAP = {
  COST: 1000,
  LOGS: 95e6,
};

/**
 * Consumes cost in whole integers and
 * calculates the amount of related logs.
 */
export const calculateLogsFromCost = (cost: number): number => {
  if (cost === METRICS_BASE.COST) return METRICS_BASE.LOGS;

  const currentOverageCost = cost - METRICS_BASE.COST;
  const currentOverageLogs = (currentOverageCost / METRICS_OVERAGE.COST) * METRICS_OVERAGE.LOGS;
  return METRICS_BASE.LOGS + currentOverageLogs;
};

export const DEFAULT_SPENDING_LIMIT = 250;
export const DEFAULT_LOG_CAP: number = calculateLogsFromCost(DEFAULT_SPENDING_LIMIT);

/** **************************** */
