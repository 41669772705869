/**
 * @note  We can't import this function from the angular app because there
 * are jest esm dependency issues with other consumers of this function. So
 * this function has been duplicated in public/js/factories/Project.js.
 * If you change this function, any changes here should be reflected there as well.
 */
export function daysRemaining(deadline: Date | string | null | undefined): number {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const trialEndsAt = new Date(deadline || 0);
  const currentTime = new Date(Date.now());
  const trialDaysRemaining = Math.round((trialEndsAt.getTime() - currentTime.getTime()) / oneDay);
  return Math.max(trialDaysRemaining, 0);
}
