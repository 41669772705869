/**
 * Matches a Git commit hash (SHA-1).
 *
 * @example 1857349e7519b0b194b6a59a363056599b9dbeb6
 * @example bb9d1
 */
export const COMMIT_HASH_REGEX: RegExp = /\b[0-9a-f]{5,40}\b/;

/**
 * @example #fff
 * @example #6184a7
 */
const VALID_HEX_PATTERN = [3, 4, 6, 8].map(digitCount => `#[0-9a-fA-F]{${digitCount}}`).join('|');
export const HEX_COLOR: RegExp = new RegExp(`^(?:${VALID_HEX_PATTERN})$`, 'i');

/**
 * Matches `new Date().toISOString()` strings.
 *
 * @example 2023-04-03T06:30:05.973Z
 */
export const ISO_DATE_REGEX: RegExp = /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d.\d\d\dZ/;

/**
 * @example 1
 * @example 1.1
 * @example 1.1.1
 * @example 4-debug.test
 */
export const SEMVER_REGEX: RegExp = /([0-9]+)(?:\.([0-9]+))?(?:\.([0-9]+))?(-.*)?/;

/**
 * @example getting-started
 * @example Getting Started
 * @example côte-divoire
 */
export const SLUG_REGEX: RegExp = /([a-z0-9-_ ]|[^\\x00-\\x7F])+/i;
