import type { EndpointDataType } from '@readme/api/src/mappings/page/reference/types';
import type { OperationObject, HttpMethods, OASDocument, SecuritySchemeObject, ParameterObject } from 'oas/types';

import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { actionLog, createBoundedUseStore, isClient } from '@core/store/util';

import { createRequestExampleEditorSlice, type RequestExampleEditorSlice } from './RequestExampleEditor';
import { createResponseExampleEditorSlice, type ResponseExampleEditorSlice } from './ResponseExampleEditor';

interface APIDesignerStoreState {
  /**
   * The api information that comes from APIv2. Includes spec, as well as the current path and method.
   */
  apiObject?: EndpointDataType;

  /**
   * Errors that occur when validating the API Designer form components.
   */
  errors: Record<string, string>;

  /**
   * Indicates whether the reference store is fully hydrated with incoming data
   * either from our SSR props, API endpoint or some other call to reset() that
   * moves us out of our "default" starting state.
   */
  isReady: boolean;
}

interface APIDesignerStoreActions {
  /**
   * Adds a new parameter to the current endpoint
   */
  addEmptyParameter: (location: Exclude<ParameterObject['in'], 'cookie'>) => void;

  /**
   * Adds an empty request body to the current endpoint
   */
  addEmptyRequestBody: () => void;

  /**
   * Removes the error message for a specific field. If no field is provided, all
   * errors are cleared.
   */
  clearErrors: (field?: string) => void;

  /** Creates a new operation in the OAS file */
  createOperation: () => void;

  /**
   * Returns the available security schemes for the current OAS file
   */
  getAvailableSecuritySchemes: () => Record<string, SecuritySchemeObject>;

  /**
   * Returns the operation on the OAS file for the current path and method
   */
  getCurrentOperation: () => {
    currentMethod: HttpMethods;
    currentPath: string;
    operation: OperationObject | undefined;
  };

  /**
   * Initializes the API Designer store. Used by `InitializeAPIDesignerStore`.
   */
  initialize: (opts: { apiObject: EndpointDataType }) => void;

  /**
   * Removes a parameter from the current endpoint.
   */
  removeParameter: (location: Exclude<ParameterObject['in'], 'cookie'>, name: string) => void;

  /**
   * Removes the request body from the current endpoint
   */
  removeRequestBody: () => void;

  /**
   * Resets state back to the last "initialized" state. When `partialState` is
   * provided, it is merged into state and re-initialized to this.
   */
  reset: () => void;

  /**
   * Updates the current API object.
   */
  setApiObject: (apiObject: EndpointDataType) => void;

  /**
   * Updates the errors object with a new error message.
   */
  setError: (field: string, message: string) => void;

  /**
   * Updates the method of an operation.
   */
  setMethod: (newMethod: HttpMethods) => void;

  /**
   * Updates the current OpenAPI definition within the API object.
   */
  setOas: (oas: OASDocument) => void;

  /**
   * Updates the path of an operation.
   */
  setPath: (newPath: string) => void;

  /**
   * Toggles the security scheme for the current operation.
   */
  toggleSecurityScheme: (scheme: string) => void;
}

export type APIDesignerStore = APIDesignerStoreActions &
  APIDesignerStoreState &
  RequestExampleEditorSlice &
  ResponseExampleEditorSlice;

const initialState: APIDesignerStoreState = {
  isReady: false,
  errors: {},
};

export const apiDesignerStore = createStore<APIDesignerStore>()(
  devtools(
    immer((set, get, ...props) => {
      /**
       * Holds reference to the initial state so we can support resetting the
       * store back to this state when calling `reset()`.
       */
      const resetState = {
        ...initialState,
        ...createRequestExampleEditorSlice(set, get, ...props),
        ...createResponseExampleEditorSlice(set, get, ...props),
      };

      return {
        ...resetState,

        initialize: ({ apiObject }) => {
          get().setApiObject(apiObject);
          get().requestExampleEditor.initialize();
          get().responseExampleEditor.initialize();

          // The createDefaultReferencePage() function will set the api object with
          // an empty string as the placeholder path value. If the API Designer
          // initializes with this empty path, it tells us we need to populate the
          // current state with a new operation.
          if (apiObject?.path === '') {
            get().createOperation();
          }

          set(
            state => {
              // When running on the server, we must avoid marking this store
              // as "ready" to ensure it continues receiving updates until it
              // gets initialized on the client's first render.
              if (isClient) {
                state.isReady = true;
              }
            },
            false,
            actionLog('initialize', apiObject),
          );
        },

        addEmptyParameter: location => {
          const newSchema = { in: location, name: '', schema: { type: 'string' } };
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              if (!state.apiObject.schema) throw new Error('schema is required');
              if (!state.apiObject.schema.paths[state.apiObject.path][state.apiObject.method].parameters) {
                state.apiObject.schema.paths[state.apiObject.path][state.apiObject.method].parameters = [];
              }
              state.apiObject.schema.paths[state.apiObject.path][state.apiObject.method].parameters.push(newSchema);
            },
            false,
            actionLog('addEmptyParameter', { location }),
          );
        },

        addEmptyRequestBody: () => {
          const newSchema = { properties: {}, type: 'object' };
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              if (!state.apiObject.schema) throw new Error('schema is required');
              if (!state.apiObject.schema.paths) throw new Error('paths is required');
              if (!state.apiObject.path) throw new Error('path is required');
              if (!state.apiObject.method) throw new Error('method is required');
              state.apiObject.schema.paths[state.apiObject.path][state.apiObject.method].requestBody = {
                content: {
                  'application/json': { schema: newSchema },
                },
              };
            },
            false,
            actionLog('addEmptyRequestBody'),
          );
        },

        getCurrentOperation: () => {
          const oas = get().apiObject?.schema;
          const currentPath = get().apiObject?.path as string;
          const currentMethod = get().apiObject?.method as HttpMethods;
          const operation = oas?.paths?.[currentPath]?.[currentMethod];

          return { currentMethod, currentPath, operation };
        },

        getAvailableSecuritySchemes: () => {
          const oas = get().apiObject?.schema;
          return oas?.components?.securitySchemes || {};
        },

        removeRequestBody: () => {
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              if (!state.apiObject.schema) throw new Error('schema is required');
              if (!state.apiObject.schema.paths) throw new Error('paths is required');
              if (!state.apiObject.path) throw new Error('path is required');
              if (!state.apiObject.method) throw new Error('method is required');
              delete state.apiObject.schema.paths[state.apiObject.path][state.apiObject.method].requestBody;
            },
            false,
            actionLog('removeRequestBody'),
          );
        },

        removeParameter(location, name) {
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              if (!state.apiObject.schema) throw new Error('schema is required');
              if (!state.apiObject.schema.paths) throw new Error('paths is required');
              if (!state.apiObject.path) throw new Error('path is required');
              if (!state.apiObject.method) throw new Error('method is required');
              const filteredParameters = state.apiObject.schema.paths[state.apiObject.path][
                state.apiObject.method
              ].parameters.filter(p => p.in !== location || p.name !== name);
              state.apiObject.schema.paths[state.apiObject.path][state.apiObject.method].parameters =
                filteredParameters;
            },
            false,
            actionLog('removeParameter', { location, name }),
          );
        },

        reset: () => {
          set(resetState, false, actionLog('reset'));
        },

        setApiObject: apiObject => {
          set(
            state => {
              state.apiObject = apiObject;
            },
            false,
            actionLog('setApiObject', { apiObject }),
          );
        },

        setOas: oas => {
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              state.apiObject.schema = oas;
            },
            false,
            actionLog('setOas', { oas }),
          );
        },

        setMethod: newMethod => {
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              if (!state.apiObject.schema) throw new Error('schema is required');
              if (!state.apiObject.schema.paths) throw new Error('paths is required');
              if (!state.apiObject.path) throw new Error('path is required');
              if (!state.apiObject.method) throw new Error('method is required');
              if (!state.apiObject.schema.paths[state.apiObject.path])
                throw new Error(`path ${state.apiObject.path} is required`);
              state.apiObject.schema.paths[state.apiObject.path]![newMethod] =
                state.apiObject.schema.paths[state.apiObject.path]![state.apiObject.method];
              delete state.apiObject.schema.paths[state.apiObject.path]![state.apiObject.method];
              state.apiObject.method = newMethod;
            },
            false,
            actionLog('setMethod', { newMethod }),
          );
        },

        setPath: newPath => {
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              if (!state.apiObject.path) throw new Error('path is required');
              if (!state.apiObject.method) throw new Error('method is required');
              const method = state.apiObject.method;
              const path = state.apiObject.path;
              if (!state.apiObject.schema) throw new Error('schema is required');
              if (!state.apiObject.schema.paths) throw new Error('paths is required');
              if (!state.apiObject.schema.paths[path]) throw new Error('missing path');

              // Update the current path in the API object
              state.apiObject.path = newPath;

              // Update the new path in the schema
              if (!state.apiObject.schema.paths[newPath]) state.apiObject.schema.paths[newPath] = {};
              state.apiObject.schema.paths[newPath][method] = state.apiObject.schema.paths[path][method];

              // Delete the old operation
              delete state.apiObject.schema.paths[path][method];

              // This was the only operation in the path
              if (Object.keys(state.apiObject.schema.paths[path]).length === 0) {
                delete state.apiObject.schema.paths[path];
              }

              let parameters = state.apiObject.schema.paths[newPath][method].parameters;
              // If there aren't any parameters yet, this won't be in the object
              if (!parameters) {
                parameters = [];
              }

              // Make sure newly added path params are added to the spec
              const expectedPathParams = Array.from(newPath.matchAll(/({([^}]+)})/g), m => m[2]);
              const missingPathParams = expectedPathParams
                .map(param => {
                  const pathParam = parameters.find(p => p.name === param);
                  if (!pathParam) {
                    return { in: 'path', name: param, schema: { type: 'string' }, required: true };
                  }
                  return false;
                })
                .filter(Boolean);

              // Only want to add parameters if there are some missing
              if (missingPathParams.length > 0) {
                state.apiObject.schema.paths[newPath][method].parameters = [...parameters, ...missingPathParams];
              } else if (parameters.length > 0) {
                state.apiObject.schema.paths[newPath][method].parameters = parameters;
              }

              if (state.apiObject.schema.paths[newPath][method].parameters) {
                // Remove path params that aren't in the path anymore
                state.apiObject.schema.paths[newPath][method].parameters = state.apiObject.schema.paths[newPath][
                  method
                ].parameters.filter(
                  p =>
                    p.in !== 'path' ||
                    expectedPathParams.some(expectedName => p.name === expectedName && p.in === 'path'),
                );
              }
            },
            false,
            actionLog('setPath', { newPath }),
          );
        },

        toggleSecurityScheme: (scheme: string) => {
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              if (!state.apiObject.schema) throw new Error('schema is required');
              if (!state.apiObject.schema.paths) throw new Error('paths is required');
              if (!state.apiObject.path) throw new Error('path is required');
              if (!state.apiObject.method) throw new Error('method is required');
              const currentPath = state.apiObject.path;
              const currentMethod = state.apiObject.method;

              if (!state.apiObject.schema.paths[currentPath][currentMethod].security) {
                state.apiObject.schema.paths[currentPath][currentMethod].security = [];
              }

              let security = state.apiObject.schema.paths[currentPath][currentMethod].security;

              if (!security.some(s => s[scheme])) {
                security.push({ [scheme]: [] });
              } else {
                // Remove the scheme from the security array
                security = security.filter(s => !s[scheme]);
              }

              state.apiObject.schema.paths[currentPath][currentMethod].security = security;
            },
            false,
            actionLog('toggleSecurityScheme', { scheme }),
          );
        },

        createOperation: () => {
          set(
            state => {
              if (!state.apiObject) throw new Error('apiObject is required');
              if (!state.apiObject.schema) throw new Error('schema is required');
              let newEndpointPath = '/new-endpoint';
              let i = 1;
              while (state.apiObject.schema.paths[newEndpointPath]?.get) {
                newEndpointPath = `/new-endpoint-${i}`;
                i += 1;
              }
              state.apiObject.path = newEndpointPath;
              state.apiObject.method = 'get';
              const newOperation = {
                get: {
                  description: '',
                  operationId: '',
                  responses: {
                    200: {
                      description: '',
                    },
                  },
                  parameters: [],
                },
              };
              if (!state.apiObject?.schema?.paths) {
                state.apiObject.schema = { ...state.apiObject.schema, paths: { [newEndpointPath]: newOperation } };
              } else if (state.apiObject.schema.paths[newEndpointPath]) {
                state.apiObject.schema.paths[newEndpointPath].get = newOperation.get;
              } else {
                state.apiObject.schema.paths = { ...state.apiObject.schema.paths, [newEndpointPath]: newOperation };
              }
            },
            false,
            actionLog('createOperation'),
          );
        },

        setError: (field, message) => {
          set(
            state => {
              state.errors[field] = message;
            },
            false,
            actionLog('setError', { field, message }),
          );
        },

        clearErrors: field => {
          set(
            state => {
              if (field) {
                delete state.errors[field];
              } else {
                state.errors = {};
              }
            },
            false,
            actionLog('clearError', { field }),
          );
        },
      };
    }),
    { name: 'APIDesignerStore' },
  ),
);

export const useAPIDesignerStore = createBoundedUseStore(apiDesignerStore);

export * from './InitializeAPIDesignerStore';
