import type {
  CustomCodeSamples,
  LibraryTarget,
  ReferenceLanguageSlice,
  ReferenceLanguageSlicePersistedState,
  ReferenceLanguageSliceState,
} from './types';
import type { SupportedTargets } from '@readme/oas-to-snippet/languages';
import type { StateStorage } from 'zustand/middleware';

import { getSupportedLanguages } from '@readme/oas-to-snippet/languages';
import { uppercase } from '@readme/syntax-highlighter';
import httpsnippetClientAPIPlugin from 'httpsnippet-client-api';
import Cookie from 'js-cookie';
import cloneDeep from 'lodash/cloneDeep'; // eslint-disable-line you-dont-need-lodash-underscore/clone-deep

import { safelyParseJSON } from '@core/utils/json';

export const DEFAULT_LANGUAGES = ['shell', 'node', 'ruby', 'php', 'python', 'java', 'csharp'] as const;

export const LANGUAGE_COOKIE = 'readme_language';
export const LIBRARY_COOKIE = 'readme_library';

/**
 * Array of special items to treat as languages
 * Example: AWS API Gateway is not a true "language" in our data model: it's a library with "sub" languages
 * This allows LanguagePicker to support selecting languages like "aws" without them being in allLanguages
 * Note: Custom handling of the snippet is still required for languages here to work properly
 * */
export const TREAT_AS_LANGUAGE = ['aws', 'dotnet'] as const;

// We need to clone this otherwise the immer middleware will mess with the original object
const supportedLanguages = cloneDeep(
  getSupportedLanguages({
    plugins: [httpsnippetClientAPIPlugin],
  }),
);

export const allLanguages = Object.keys(supportedLanguages).filter(lang => lang.toLowerCase()) as SupportedTargets[];

/**
 * Returns a formatted language name
 */
export function getLanguageName(language: string): string {
  if (language === 'aws') {
    return 'API Gateway';
  }
  if (language === 'dotnet') {
    return '.NET';
  }

  return uppercase(language);
}

/**
 * Normalizes language for usage in cookie. If language is missing or invalid,
 * returns `false`.
 */
function normalizeLanguageCookie(lang: unknown): SupportedTargets | false {
  if (lang === undefined) {
    return false;
  } else if (allLanguages.includes(lang as SupportedTargets)) {
    return lang as SupportedTargets;
  } else if (typeof lang === 'string' && lang.toLowerCase() === 'curl') {
    // Backwards compatibility with folks that have `curl` set as their cookie.
    return 'shell';
  }

  return false;
}

/**
 * retrieves the cookie that stores the user's currently selected language
 * @example node
 */
function getLanguageCookie(): SupportedTargets | false {
  const lang = Cookie.get(LANGUAGE_COOKIE);
  const normalized = normalizeLanguageCookie(lang);
  if (normalized) {
    return normalized;
  }

  // If the cookie holds a language that is unrecognized, we should get rid of the cookie.
  Cookie.remove(LANGUAGE_COOKIE);
  return false;
}

/**
 * retrieves the cookie that stores the user's library preferences
 * @example {"shell":"httpie","node":"fetch"}
 */
function getLanguageLibraryCookie(): Record<string, string> {
  const cookie = Cookie.get(LIBRARY_COOKIE);
  if (!cookie) {
    return {};
  }

  try {
    return JSON.parse(cookie) as Record<string, string>;
  } catch {
    return {};
  }
}

/**
 * With a given language return back every library that the user can select to use to generate
 * code snippets for.
 *
 */
export function getAvailableLibraries(
  customCodeSamples: CustomCodeSamples,
  lang: ReferenceLanguageSliceState['language'],
  supportsSimpleMode?: boolean,
): LibraryTarget[] {
  let libraries: LibraryTarget[] = [];

  if (!lang) return libraries;

  /**
   * There are some libraries that we want to treat as languages in the picker
   * Ex. AWS API Gateway is a library itself, but we want to treat it as a language
   * In this case, we can just return an empty set of libraries for the lang here
   * */
  if (lang && TREAT_AS_LANGUAGE.includes(lang as 'aws' | 'dotnet')) {
    return libraries;
  }

  const languageConfig = supportedLanguages[lang].httpsnippet;

  if (customCodeSamples && Array.isArray(customCodeSamples)) {
    // If we have custom code samples defined in the `x-code-samples` OAS extension we need to
    // run through and see if any match up with the current `language`. If we do, we need to run
    // through them, validate and reshape into usable library targets that match our
    // `supportedLanguages` exports in `@readme/oas-to-snippet`.
    let namelessCodeSamples = 0;
    libraries = customCodeSamples
      .filter(sample => {
        if (sample !== null && typeof sample === 'object' && 'language' in sample && 'code' in sample) {
          if (sample.language === lang) {
            return true;
          } else if (sample.language === 'curl' && lang === 'shell') {
            // Retain backwards compatibility with `curl` presence in `x-code-samples` configs.
            return true;
          }
        }

        return false;
      })
      .map(sample => {
        namelessCodeSamples += 1;

        const name =
          sample.name && sample.name.length > 0
            ? sample.name
            : `Default${namelessCodeSamples > 1 ? ` #${namelessCodeSamples}` : ''}`;

        return {
          // Having a defined name for custom samples isn't required so we need to do our best to
          // make them look nice.
          name,
          snippet: {
            highlightMode: supportedLanguages[lang].highlight,
            code: sample.code,
            correspondingExample: sample.correspondingExample,
          },
          install: sample.install,
          target: name,
          id: [lang, name],
        };
      });
  }

  return libraries
    .concat(
      customCodeSamples
        ? []
        : (Object.keys(languageConfig.targets).map(t => {
            // If this project has simple mode disabled globally across their docs, or on this
            // specific operation we need to hide `api` snippets from being surfaced.
            if (t === 'api' && !supportsSimpleMode) {
              return false;
            }

            return {
              ...languageConfig.targets[t],
              target: t,
              id: [lang, t],
            };
          }) as LibraryTarget[]),
    )
    .filter(Boolean);
}

/**
 * Returns the main languages that appear in the language picker,
 * with the maximum length of this array being the value of `maxLanguages`
 * @example ['shell', 'node', 'ruby', 'php', 'python']
 * @example ['shell', 'node', 'ruby', 'php', 'python', 'java', 'csharp']
 */
export function getDefaultLanguages(
  availableLanguages: ReferenceLanguageSliceState['availableLanguages'],
  providedLanguages: string[],
  maxLanguages: ReferenceLanguageSliceState['maxLanguages'],
): string[] {
  let defaults: string[] = [];
  if (providedLanguages.length) {
    defaults = providedLanguages.slice(0, maxLanguages);
  }

  if (!defaults.length) {
    defaults = DEFAULT_LANGUAGES.slice(0, maxLanguages);
  } else if (maxLanguages === 7) {
    // If we want to render out seven max languages for the Realtime Metrics view but their API
    // definition has our old five defaults present (in their `x-readme.samples-languages` OAS
    // extension) then we should swap out what they have with the new seven defaults so that we'll
    // show the user a total of seven languages on the Realtime Metrics view.
    if (String(defaults) === String(['shell', 'node', 'ruby', 'php', 'python'])) {
      defaults = DEFAULT_LANGUAGES.slice(0, maxLanguages);
    }
  }

  // If the language cookie is present, and that language is not already in our set of defaults
  // we should add it.
  const languageCookie = getLanguageCookie();
  if (languageCookie) {
    if (availableLanguages.includes(languageCookie) && !defaults.includes(languageCookie)) {
      // If we have our maximum amount of languages to show already we we should swap out the
      // last entry with the language from our cookie.
      if (defaults.length === maxLanguages) {
        defaults.pop();
      }

      defaults.push(languageCookie);
    }
  }

  return defaults;
}

/**
 * Determine what the default language the user should see is.
 *
 * It will utilize the `language` parameter and ensure that
 * it is present in `defaultLanguages`.
 *
 * If `language` is empty, then it will fall back to the language cookie,
 * and if that's not present then it'll fall back to the first item in `defaultLanguages`.
 */
export function getDefaultLanguage(
  defaultLanguages: string[],
  language: ReferenceLanguageSliceState['language'] | string,
  useAllAvailableLanguages: boolean,
): SupportedTargets {
  let def = defaultLanguages[0];
  const languageCookie = getLanguageCookie();
  if (languageCookie) {
    def = languageCookie;
  } else if (language) {
    // We renamed our main cURL language target to Shell (and made cURL as a subset of that), but
    // to retain all possible backwards compatibility everywhere we should adhoc mod this.
    def = language === 'curl' ? 'shell' : language;
  }

  // If we shouldn't use all available languages and the language that we want to have as our
  // default isn't in the list of languages that the user should see, then we shouldn't show them
  // that language and instead fallback to the first in the list.
  //
  // We should however keep the users' language cookie data intact though and only reset that if
  // they click on another language. We do this so that if they go back to where they were where
  // they should be able to use all available languages they still have the language they had
  // there.
  //
  // For example if you load up a reference guide and want to see cURL snippets you should see
  // cURL snippets there, but then if that same user loads up Personalized Docs in the Dash
  // because we don't have any cURL languages available there then their default language
  // shouldn't be cURL, it should be the first available language that Personalized Docs
  // supports.
  if (!useAllAvailableLanguages) {
    if (!defaultLanguages.includes(def)) {
      def = defaultLanguages[0];
    }
  }

  return def as SupportedTargets;
}

/**
 * Determine what our default language library should be. What they see depends on the language
 * that they're seeing. For example if you have Node as your language, you should see `api` as
 * the default language, not Axios.
 *
 */
export function getDefaultLibraryForLanguage(
  customCodeSamples: CustomCodeSamples,
  lang: ReferenceLanguageSliceState['language'],
  libraries: LibraryTarget[],
  supportsSimpleMode?: boolean,
): LibraryTarget | null {
  /**
   * There are some libraries that we want to treat as languages in the picker
   * Ex. AWS API Gateway is a library itself, but we want to treat it as a language
   * In this case, we can just return null
   * */
  if (!lang || TREAT_AS_LANGUAGE.includes(lang as 'aws' | 'dotnet')) {
    return null;
  }

  const languageConfig = supportedLanguages[lang].httpsnippet;
  if (Array.isArray(customCodeSamples)) {
    // If we had useable custom samples let's use the first one as our initial library.
    if (libraries.length) {
      return libraries[0];
    }
  }

  const libraryCookie = Cookie.get(LIBRARY_COOKIE);
  if (libraryCookie) {
    try {
      const cook = JSON.parse(libraryCookie);
      if (typeof cook === 'object' && cook !== null && lang in cook) {
        // If the library we have in the cookie is a real target then we should use it!
        const lib = libraries.find(target => target.target === cook[lang]);
        if (lib) {
          return lib;
        }
      }
    } catch {
      // Cookie is in a weird unparseable state so let's get rid of it.
      Cookie.remove(LIBRARY_COOKIE);
    }
  }

  // Since the `supportedLanguages` export in `@readme/oas-to-snippet` is used in
  // `@readme/api-explorer`, for backwards compatibility we're still setting the default target
  // for Node there to `node-fetch`. Since we want it to default to `api` now we need to override
  // that here. Eventually when the old API Explorer is decommissioned we can update
  // `oas-to-snippet` with this new default.
  let target = languageConfig.default;
  if (lang === 'node' && supportsSimpleMode) {
    target = 'api';
  }

  return {
    ...languageConfig.targets[target],
    target,
    id: [lang, target],
  };
}

/**
 * For a given array of custom code samples and the currently selected language,
 * returns `true` if the currently selected language has a defined custom code sample.
 */
export function isCustomSampleSelected(
  customCodeSamples: CustomCodeSamples,
  lang: ReferenceLanguageSliceState['language'],
) {
  return (customCodeSamples || []).some(sample => {
    // remaps 'curl' to 'shell'
    return (sample.language === 'curl' ? 'shell' : sample.language) === lang;
  });
}

/**
 * Sorts array of languages alphabetically,
 * while putting special cases like AWS and .NET at the very end
 */
export function sortLanguages(arr: string[]): string[] {
  const languagesToAddToEnd: string[] = [];
  // for each special language, remove it from the array
  TREAT_AS_LANGUAGE.forEach(specialLang => {
    const index = arr.indexOf(specialLang);
    if (index !== -1) {
      arr.splice(index, 1);
      languagesToAddToEnd.push(specialLang);
    }
  });

  arr.sort((a, b) => {
    // We need to sort by the /real/ language name here because C++ and C# are specified as
    // `cplusplus` and `csharp` and if we don't sort by their proper names they'll get appear
    // after Clojure.
    const alcase = getLanguageName(a).toLowerCase();
    const blcase = getLanguageName(b).toLowerCase();

    if (alcase < blcase) {
      return -1;
    } else if (alcase > blcase) {
      return 1;
    }

    return 0;
  });

  return arr.concat(languagesToAddToEnd);
}

/**
 * Syncs the current state with cookie storage
 */
export const languageCookieStorage: StateStorage = {
  // This function allows us to read the language cookie and
  // merge it with the state before it's initialized on the client
  getItem: () => {
    const language = getLanguageCookie();
    if (language) {
      const state: ReferenceLanguageSlicePersistedState = { language: { language } };
      return JSON.stringify({ state });
    }
    return null;
  },
  // Currently not used(?)
  removeItem: () => {},
  // Every time the state changes, this function runs
  // which allows us to persist the state to the cookies
  setItem: async (_name: string, value: string): Promise<void> => {
    let state: Partial<ReferenceLanguageSlice> = {};
    state = safelyParseJSON(value)?.state;
    const { language, languageLibrary } = state?.language || {};

    const normalizedLang = normalizeLanguageCookie(language);
    if (normalizedLang) {
      Cookie.set(LANGUAGE_COOKIE, normalizedLang);
    } else {
      Cookie.remove(LANGUAGE_COOKIE);
    }

    if (language && languageLibrary?.target) {
      const libraryCookie = getLanguageLibraryCookie();
      libraryCookie[language] = languageLibrary.target;
      Cookie.set(LIBRARY_COOKIE, JSON.stringify(libraryCookie));
    }
  },
};
