/* eslint-disable readme-internal/no-mongoose-parent-field-collision */
import type { ProjectClientSide, ProjectDocument } from '@readme/backend/models/project/types';

/**
 * Small wrapper for creating `encryptedLocalStorageKey` values.
 */
export function createEncryptedKey(
  /** The project to compute the key for */
  project: ProjectClientSide | ProjectDocument,
  uuid: string,
) {
  let projectId = '';
  try {
    projectId = project?._parent?._id?.toString();
    if (!projectId) {
      if (project.parent && '_id' in project.parent) {
        projectId = project?.parent?._id?.toString();
      } else {
        projectId = project?._id?.toString();
      }
    }
  } catch (e) {
    /* empty */
  }

  const superHub = project?.flags?.superHub || false;

  return `${btoa(projectId || '')}-${superHub}-${uuid}`;
}
