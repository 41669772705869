import React, { memo, forwardRef } from 'react';

import './style.scss';

/** Coerce strings to integers or
 *  convert them to CSS variables.
 */
export const cssUnitOrVar = (val: number | string | undefined) =>
  Number(val) ? `${val}px` : typeof val === 'string' && !val.match(/^\d+/) ? `var(--${val})` : val;

type SpaceToken = 'lg' | 'md' | 'sm' | 'xl' | 'xs' | 'xxl';

export type FlexProps = React.HTMLAttributes<HTMLElement> & {
  [key: string]: unknown; // enable variable props when using a custom Tag
  align?: 'baseline' | 'center' | 'end' | 'start' | 'stretch';
  basis?: number | string;
  className?: string;
  gap?: SpaceToken | number | string;
  grow?: number | string;
  inline?: boolean | string;
  justify?: 'around' | 'between' | 'center' | 'end' | 'start';
  layout?: 'col' | 'row';
  shrink?: number | string;
  style?: React.CSSProperties;
  tag?: React.ElementType;
  text?: 'center' | 'left' | 'right';
  wrap?: 'nowrap' | 'wrap-reverse' | 'wrap';
} & (React.ButtonHTMLAttributes<HTMLButtonElement> | React.LabelHTMLAttributes<HTMLLabelElement>);

const Flex = memo(
  forwardRef(
    (
      {
        align = 'start',
        basis,
        className,
        gap = 'md',
        grow,
        justify = 'between',
        layout = 'row',
        shrink,
        style,
        tag: Tag = 'div',
        wrap,
        ...props
      }: FlexProps,
      ref: React.ForwardedRef<HTMLElement>,
    ) => {
      return (
        <Tag
          {...props}
          ref={ref}
          align={align}
          className={`Flex Flex_${layout} ${className || ''}`}
          justify={justify}
          style={{
            '--flex-gap': cssUnitOrVar(gap),
            ...(grow && { flexGrow: grow }),
            ...(shrink && { flexShrink: shrink }),
            ...(basis && { flexBasis: basis }),
            ...(wrap && { flexWrap: wrap }),
            ...style,
          }}
        >
          {props.children}
        </Tag>
      );
    },
  ),
);

Flex.displayName = 'Flex';

export default Flex;
