import type { Options as UrlManagerOptions } from '@readme/server-shared/UrlManager';

export interface IProjectMetadata {
  id: string;
  modules: Record<string, boolean>;
  name?: string;
  nav_names: Record<string, string>;
  subdomain: string;
  subpath?: string;
}

export interface IAlgoliaSearch {
  appId: string;
  id?: string;
  indexName: string;
  isModalOpen: boolean;
  projectsMeta: IProjectMetadata[];
  searchApiKey: string;
  urlManagerOpts: UrlManagerOptions;
}

export enum ClientTypes {
  LIVE = 'LIVE',
  PROXY = 'PROXY',
}

export enum TabNames {
  All = 'All',
  Changelog = 'Changelog',
  Discussions = 'Discussions',
  Guides = 'Guides',
  CustomPages = 'Pages',
  Recipes = 'Recipes',
  Reference = 'Reference',
}

export enum IndexNames {
  All = '',
  Changelog = 'changelog',
  CustomPages = 'custompages',
  Discussions = 'discuss',
  Guides = 'docs',
  Reference = 'reference',
  Recipes = 'tutorials',
}

export interface ITabDefaults {
  altLabel?: string;
  icon: React.ReactNode;
  label: TabNames;
  moduleName: IndexNames;
}

export interface ISearchContext {
  children: React.ReactNode;
  handleKeyboardEvent: (event: React.KeyboardEvent) => void;
  projectMetadata: IProjectMetadata[];
  subdomain: string;
}
