import mergeWith from 'lodash/mergeWith';
import op from 'object-path';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo, useState, useRef } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

export const ProjectContext = createContext({ project: {}, setProject: () => {} });
ProjectContext.displayName = 'ProjectContext';

const useProjectState = initial => {
  // Store project into a ref that we can access inside the mutateProject callback
  // without adding project as the useCallback dependency. This prevents mutateProject from updating
  // itself every time it is called, which could cause unnecessary re-renders
  const [project, setProject] = useState(initial);
  const projectRef = useRef(project);
  projectRef.current = project;

  const mutateProject = useCallback((update = '', value, { rerender = true, mergeUnknown = false } = {}) => {
    if (typeof update === 'object') {
      const clean = Object.entries(update).reduce((all, [key, val]) => {
        if (mergeUnknown || key in projectRef.current) all[key] = val;
        return all;
      }, {});
      mergeWith(projectRef.current, clean, (objValue, srcValue) => {
        // We don't want to partially merge array values. Instead, we
        // want to replace the entire array with the new one.
        return Array.isArray(objValue) ? srcValue : undefined;
      });
    } else if ((mergeUnknown || op.has(projectRef.current, update)) && value !== null) {
      if (value !== op.get(projectRef.current, update)) op.set(projectRef.current, update, value);
      else rerender = false; // eslint-disable-line no-param-reassign
    }
    if (rerender) setProject({ ...projectRef.current });
  }, []);

  return [project, mutateProject, setProject];
};

export const ProjectState = ({ value: initialProjectDataFromSSRProps, children }) => {
  const { isClient } = useEnvInfo();
  const [project, mutateProject, setProject] = useProjectState(() => {
    const projectData = initialProjectDataFromSSRProps;

    if (projectData.appearance) {
      // Use a ReadMe blue fallback color for the Theme if it hasn't been set
      projectData.appearance.colors.main = projectData?.appearance?.colors?.main || projectData?.appearance?.logo[4];
    }

    return projectData;
  });
  if (isClient) window.rxUpdateProjectContext = mutateProject;

  const value = useMemo(
    () => ({
      mutate: mutateProject, // internalize the custom setter
      ...project,
    }),
    [project, mutateProject],
  );

  return (
    <ProjectContext.Provider
      value={{
        project: value,
        setProject,
        defaults: initialProjectDataFromSSRProps,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

ProjectState.propTypes = {
  value: PropTypes.object,
};

export default ProjectState;
