import type { ForwardedRef } from 'react';

import React, { useMemo, forwardRef, memo } from 'react';

import useClassy from '@core/hooks/useClassy';

import classes from './style.module.scss';

export type BoxProps = React.HTMLAttributes<HTMLElement> & {
  Tag?: React.ElementType;
  forwardedRef?: React.ForwardedRef<HTMLElement>;
  kind?: 'card' | 'flat' | 'pop' | 'rule';
  theme?: 'dark-on-black' | 'dark' | 'light';
};

const Box = memo(
  forwardRef(
    ({ className, children, kind = 'flat', Tag = 'div', theme, ...rest }: BoxProps, ref: ForwardedRef<HTMLElement>) => {
      const bem = useClassy(classes, 'Box');
      const _className = useMemo(
        () =>
          `${bem(
            '&',
            `_${kind}`,
            theme === 'dark' && '_dark',
            theme === 'dark-on-black' && '_dark-on-black',
            className,
          )} Box`,
        [bem, className, kind, theme],
      );

      return (
        <Tag ref={ref} className={_className} {...rest}>
          {children}
        </Tag>
      );
    },
  ),
);

export default Box;
