/* eslint-disable perfectionist/sort-enums */
// Base Event names for Amplitude
export enum AMPLITUDE_EVENT {
  // Billing
  BILLING_ADD_ON_CANCEL = 'AddOn: Cancel',
  BILLING_ADD_ON_SUCCESS = 'AddOn: Success',
  BILLING_FAILED_PAYMENT_VIEW = 'Failed Payment: View',
  BILLING_MANAGE_ADD_ONS_CHANGE = 'Manage AddOns: Change',
  BILLING_MANAGE_PLAN_CHANGE = 'Manage Plan: Change',
  BILLING_MANAGE_PLAN_VIEW = 'Manage Plan: View',
  BILLING_PAYMENT = 'Payment: Status',
  BILLING_SUBSCRIPTION_SUCCESS = 'Subscription: Success',

  // Feature Feedback
  FEATURE_FEEDBACK = 'Feature Feedback',

  LOG_CAP_REACHED = 'Log Cap Reached',

  // Set Up API Logs (SDK)
  METRICS_SDK_CODE_COPY = 'Metrics Setup Copy',
  METRICS_SDK_CODE_SELECT = 'Metrics Setup Selection',
  METRICS_SDK_SUCCESS = 'Metrics Setup Success',
  METRICS_SDK_VIEW = 'Metrics Setup View',
  METRICS_SETUP_HELP_VIDEO = 'Metrics Setup Video: Play',
  METRICS_SLACK_CTA = 'Metrics Setup: Slack CTA',

  /**
   * Onboarding
   * Note: User Signup and Project Creation events are tracked server-side to avoid
   * dropping events from privacy-focused browser settings or ad blockers
   */
  ONBOARDING_FINISH = 'Onboarding: Finish',
  ONBOARDING_PROJECT_CREATE_SUCCESS = 'Onboarding: Project Created',
  ONBOARDING_USER_SIGNUP_SUCCESS = 'Onboarding: User Signup Success',
  ONBOARDING_VIEW = 'Onboarding: View',

  // Set Up API Keys (Webhooks)
  PD_WEBHOOK_CODE_COPY = 'PD Webhook Copy',
  PD_WEBHOOK_CODE_SELECT = 'PD Webhook Selection',
  PD_WEBHOOK_SUCCESS = 'PD Webhook Success',
  PD_WEBHOOK_TEST = 'PD Webhook Test',
  PD_WEBHOOK_VIEW = 'PD Webhook View',

  // PLP Click
  PLP_CLICK = 'PLP: Click',

  // Quickstart
  QS_CLICK = 'Quickstart: Click',
  QS_VIEW = 'Quickstart: View',
}

// Event properties for use in Amplitude events
export enum AMPLITUDE_EVENT_PROPERTY {
  // Feature Feedback
  FEATURE_ID = 'feature_id',
  FEATURE_REACTION = 'feature_reaction',
  FEATURE_REACTION_COUNTS = 'feature_reaction_counts',
  FEATURE_TITLE = 'feature_title',
  ONBOARDING_CREATE_PROJECT = 'Create Project',
  ONBOARDING_SIGNUP = 'Signup',
  ONBOARDING_UPLOAD_OAS = 'Upload OAS',
  PLP_BOOKMARK = 'PLP: Bookmark',
  QS_FINISH = 'Finish',
  QS_NEXT = 'Next',
  QS_OPEN_API_REFERENCE = 'Open API Reference',
  QS_OPEN_DISCUSSIONS = 'Open Discussions',
  QS_OPEN_GUIDES = 'Open Guides',
  QS_OPEN_LANDING_PAGE = 'Open Landing Page',
  QS_OPEN_MANUAL_EDITOR = 'Open Manual Editor',
  QS_OPEN_OAS_UPLOAD = 'Open OAS Upload',
  QS_SKIP = 'Skip',
  QS_STEP_DOCS = 'Step: Docs',
  QS_STEP_SETUP_API_KEYS = 'Step: Set Up API Keys',
  QS_STEP_SETUP_API_LOGS = 'Step: Set Up API Logs',
  QS_STEP_UPLOAD_API = 'Step: Upload API',
  QUICKSTART = 'Quickstart',
  SETUP = 'Setup',
}

// Source of user signup for Amplitude events
export enum SignupSource {
  // Direct project creation from Dash project list page (/signup/project/new)
  DashNewProject = 'Dash New Project',
  // Base signup flow (/signup)
  Signup = 'Signup',
}
