import { SEMVER_REGEX, SLUG_REGEX } from '@readme/iso';
import { StructuredRegEx } from 'structured-regex';

// https://stackoverflow.com/a/20988824
// this is a pattern that we reuse in other queries so we can't use the ^ and $ anchors
export const MONGO_OBJECTID_REGEX = /[a-f\d]{24}/i;

// this ensures no characters but the mongo object id are included
export const STRICT_MONGO_OBJECTID_REGEX = new RegExp(`^${MONGO_OBJECTID_REGEX.source}$`, 'i');

export const CATEGORY_SLUG_REGEX = /(.*)/;
export const RESOURCE_ID_OR_SLUG = new RegExp(`${MONGO_OBJECTID_REGEX.source}|${SLUG_REGEX.source}`, 'i');

/**
 * @example `/changelogs/2023-06-15-update`
 * @example `/changelogs/e175cac316a79afdd0ad3afb`
 */
export const CHANGELOG_URI_REGEX = new StructuredRegEx<{ slug: string }>(
  new RegExp(`/changelogs/(${MONGO_OBJECTID_REGEX.source}|${SLUG_REGEX.source})`, 'i'),
  {
    slug: 1,
  },
);

/**
 * @example stable
 * @example 1.1
 * @example 1.1.1
 * @example 4-debug.test
 */
export const VERSION_REGEX = new StructuredRegEx<{ version: string }>(
  new RegExp(`stable|${SEMVER_REGEX.source}`, 'i'),
  {
    version: 0,
  },
);

/**
 * @example `/versions/1.1`
 * @example `/versions/stable`
 */
export const VERSION_URI_REGEX = new StructuredRegEx<{ version: string }>(
  new RegExp(`/versions/(${VERSION_REGEX.source})`, 'i'),
  {
    version: 1,
  },
);

/**
 * @example `/versions/stable/custom_blocks/e175cac316a79afdd0ad3afb`
 * @example `/versions/45cbc4a0e4123f6920000000/custom_blocks/e175cac316a79afdd0ad3afb`
 */
export const CUSTOM_BLOCK_URI_REGEX = new StructuredRegEx<{ id: string; version: string }>(
  new RegExp(`/versions/(stable|${MONGO_OBJECTID_REGEX.source})/custom_blocks/(${MONGO_OBJECTID_REGEX.source})`, 'i'),
  { version: 1, id: 2 },
);

/**
 * @example `/versions/stable/custom_blocks/page-slug`
 * @example `/versions/1.0.0/custom_blocks/page-slug`
 */
export const CUSTOM_BLOCK_GIT_URI_REGEX = new StructuredRegEx<{ tag: string; version: string }>(
  new RegExp(`/versions/(${VERSION_REGEX.source})/custom_blocks/(\\w+)`, 'i'),
  {
    version: 1,
    tag: 6,
  },
);

/**
 * @example `/versions/stable/custom_pages/our-custom-page`
 * @example `/versions/1.0.0/custom_pages/e175cac316a79afdd0ad3afb`
 */
export const CUSTOM_PAGE_URI_REGEX = new StructuredRegEx<{ id: string; version: string }>(
  new RegExp(`/versions/(${VERSION_REGEX.source})/custom_pages/(${RESOURCE_ID_OR_SLUG.source})`, 'i'),
  { version: 1, id: 6 },
);

/**
 * @example `/versions/stable/guides/getting-started`
 * @example `/versions/1.1/guides/getting-started`
 * @example `/versions/stable/reference/create-pet`
 * @example `/versions/1.1/reference/create-pet`
 */
export const PAGE_URI_REGEX = new StructuredRegEx<{ section: 'guides' | 'reference'; slug: string; version: string }>(
  new RegExp(`/versions/(${VERSION_REGEX.source})/(guides|reference)/(${SLUG_REGEX.source})`, 'i'),
  {
    version: 1,
    section: 6,
    slug: 7,
  },
);

/**
 * @example `/versions/stable/categories/guides/documentation`
 * @example `/versions/1.1/categories/guides/documentation`
 */
export const CATEGORY_URI_REGEX = new StructuredRegEx<{
  section: 'guides' | 'reference';
  slug: string;
  version: string;
}>(
  new RegExp(`/versions/(${VERSION_REGEX.source})/categories/(guides|reference)/(${CATEGORY_SLUG_REGEX.source})`, 'i'),
  { version: 1, section: 6, slug: 7 },
);

/**
 * @example `/images/e175cac316a79afdd0ad3afb`
 */
export const IMAGE_URI_REGEX = new StructuredRegEx<{ id: string }>(
  new RegExp(`/images/(${MONGO_OBJECTID_REGEX.source})`, 'i'),
  { id: 1 },
);

/**
 * @example `/versions/stable/recipes/make-an-api-call`
 * @example `/versions/1.1/recipes/make-an-api-call`
 */
export const RECIPE_URI_REGEX = new StructuredRegEx<{ slug: string; version: string }>(
  new RegExp(`/versions/(${VERSION_REGEX.source})/recipes/(${SLUG_REGEX.source})`, 'i'),
  { version: 1, slug: 6 },
);

/**
 * @example petstore
 * @example devilbert-clone
 */
export const PROJECT_SUBDOMAIN_REGEX = new StructuredRegEx<{ subdomain: string }>(/[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*/, {
  subdomain: 0,
});

/**
 * @example `/projects/me`
 * @example `/projects/petstore`
 * @example `/projects/devilbert-clone`
 */
export const PROJECT_URI_REGEX = new StructuredRegEx<{ subdomain: string }>(
  new RegExp(`/projects/(me|${PROJECT_SUBDOMAIN_REGEX.source})`, 'i'),
  { subdomain: 1 },
);

/**
 * @example `petstore.json`
 * @example `petstore.yaml`
 * @example `petstore.yml`
 */
export const API_FILENAME_REGEX = new StructuredRegEx<{ filename: string }>(
  new RegExp(`(${SLUG_REGEX.source}.(json|yaml|yml))`, 'i'),
  { filename: 1 },
);

/**
 * @example `/versions/stable/apis/petstore.json`
 * @example `/versions/1.1/apis/petstore.json`
 * @example `/versions/stable/apis/petstore.yaml`
 * @example `/versions/1.1/apis/petstore.yaml`
 */
export const API_URI_REGEX = new StructuredRegEx<{ filename: string; version: string }>(
  new RegExp(`/versions/(${VERSION_REGEX.source})/apis/(${API_FILENAME_REGEX.source})`, 'i'),
  {
    version: 1,
    filename: 6,
  },
);
