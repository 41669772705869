import type { NotificationProps } from '.';

import React from 'react';

import Notification from '.';

const ErrorNotification = ({ children, ...props }: NotificationProps) => (
  <Notification ariaProps={{ 'aria-live': 'assertive', role: 'alert' }} kind="error" {...props}>
    {children}
  </Notification>
);

export default ErrorNotification;
