import React, { useContext, useState } from 'react';
import tinycolor from 'tinycolor2';

import { ProjectContext } from '@core/context';
import type { ProjectStore } from '@core/store';
import { useProjectStore } from '@core/store';

export const ThemeContext = React.createContext<Partial<Theme>>({});
ThemeContext.displayName = 'ThemeContext';

type ColorMode = ProjectStore['data']['appearance']['brand']['theme'];

interface Theme {
  'color-link-background'?: string;
  'color-link-primary'?: string;
  'color-link-primary-alpha-25'?: string;
  'color-link-primary-alpha-50'?: string;
  'color-link-primary-darken-5'?: string;
  'color-link-primary-darken-10'?: string;
  'color-link-primary-darken-20'?: string;
  'color-link-text'?: string;
  'color-login-link'?: string;
  'color-login-link-darken-10'?: string;
  'color-login-link-primary-alpha-50'?: string;
  'color-login-link-text'?: string;
  'color-primary'?: string;
  'color-primary-alpha-25'?: string;
  'color-primary-alt'?: string;
  'color-primary-darken-10'?: string;
  'color-primary-darken-20'?: string;
  'color-primary-inverse'?: string;
  colorMode: ColorMode;
  setColorMode: (mode: ColorMode) => void;
}

// Use a custom-defined `isLight` function over tinycolor's own so we
// have more control over the exact color threshold.
export const isLight = (color: tinycolor.Instance | string | undefined) => {
  return tinycolor(color).getLuminance() > 0.45;
};

// Check if a color is perceptibly darker than the default text color
export const isDark = (color: tinycolor.Instance | string | undefined) => {
  return tinycolor(color).getLuminance() < 0.02;
};

export const darken = (color: tinycolor.Instance | string | undefined) => {
  return tinycolor(color).darken(15).toHexString();
};

export const AppTheme = ({
  children,
  theme: initialTheme = {},
}: {
  children: React.ReactNode;
  theme?: Partial<Theme>;
}) => {
  const { project } = useContext(ProjectContext);
  const { appearance: { colors = {} } = {}, flags: { dashReact = false } = {} } = project;

  const [primaryColor, linkColor, headerType, gradientColor, colorScheme] = useProjectStore(s => [
    // Fall back to the logo color if the primary color hasn't been set
    // Then fallback to the default blue if logo.main is null
    s.data.appearance.brand.primary_color || s.data.appearance.logo.main?.color || '#018ef5',
    s.data.appearance.brand.link_color,
    s.data.appearance.header.type,
    s.data.appearance.header.gradient_color,
    s.data.appearance.brand.theme,
  ]);

  const [colorMode, setColorMode] = useState(colorScheme);

  const themeColors: Partial<Theme> = {
    'color-primary': primaryColor,
  };

  themeColors['color-primary-inverse'] = isLight(tinycolor(primaryColor)) ? '#384248' : '#fff';
  themeColors['color-primary-alt'] = gradientColor || tinycolor(primaryColor).darken(15).saturate(15).toHexString();
  themeColors['color-primary-darken-10'] = tinycolor(primaryColor || '#018ef5')
    .darken(10)
    .toString();
  themeColors['color-primary-darken-20'] = tinycolor(primaryColor || '#018ef5')
    .darken(20)
    .toString();
  themeColors['color-primary-alpha-25'] = tinycolor(primaryColor || '#018ef5')
    .setAlpha(0.25)
    .toRgbString();
  themeColors['color-link-primary'] = linkColor || (isLight(tinycolor(primaryColor)) ? '#384248' : primaryColor);
  themeColors['color-link-primary-darken-5'] = tinycolor(
    linkColor || (isLight(tinycolor(primaryColor)) ? '#384248' : primaryColor),
  )
    .darken(5)
    .toString();
  themeColors['color-link-primary-darken-10'] = tinycolor(
    linkColor || (isLight(tinycolor(primaryColor)) ? '#384248' : primaryColor),
  )
    .darken(10)
    .toString();
  themeColors['color-link-primary-darken-20'] = tinycolor(
    linkColor || (isLight(tinycolor(primaryColor)) ? '#384248' : primaryColor),
  )
    .darken(20)
    .toString();
  themeColors['color-link-primary-alpha-50'] = tinycolor(
    linkColor || (isLight(tinycolor(primaryColor)) ? '#384248' : primaryColor),
  )
    .setAlpha(0.5)
    .toRgbString();
  themeColors['color-link-primary-alpha-25'] = tinycolor(
    linkColor || (isLight(tinycolor(primaryColor)) ? '#384248' : primaryColor),
  )
    .setAlpha(0.25)
    .toRgbString();
  themeColors['color-link-background'] = tinycolor(
    linkColor || (isLight(tinycolor(primaryColor)) ? '#384248' : primaryColor),
  )
    .setAlpha(0.09)
    .toRgbString();
  themeColors['color-link-text'] = isLight(
    tinycolor(linkColor || (isLight(tinycolor(primaryColor)) ? '#384248' : primaryColor)),
  )
    ? '#384248'
    : '#fff';

  if (dashReact) {
    themeColors['color-login-link'] = colors.custom_login_link_color || themeColors['color-link-primary'] || '#018ef5';
    themeColors['color-login-link-text'] = isLight(themeColors['color-login-link']) ? '#384248' : '#fff';
  } else {
    themeColors['color-login-link'] = '#018ef5';
    themeColors['color-login-link-text'] = '#fff';
  }
  themeColors['color-login-link-darken-10'] = tinycolor(themeColors['color-login-link']).darken(10).toString();
  themeColors['color-login-link-primary-alpha-50'] = tinycolor(themeColors['color-login-link'])
    .setAlpha(0.5)
    .toRgbString();

  const projectStyles = { ...initialTheme, ...themeColors };
  const projectTheme: Theme = { ...projectStyles, colorMode, setColorMode };

  return (
    <ThemeContext.Provider value={projectTheme}>
      <div
        className={`App ThemeContext ThemeContext_${isLight(tinycolor(primaryColor)) ? 'light' : 'dark'} ${
          headerType === 'line' ? 'ThemeContext_line' : ''
        }`}
        style={Object.keys(projectStyles).reduce((obj, key) => {
          const val = projectStyles[key];
          obj[`--${key}`] = val;
          return obj;
        }, {})}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  );
};

export default AppTheme;
