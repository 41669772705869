import React, { forwardRef, useMemo, memo } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import AsyncIcon from './AsyncIcon';
import classes from './style.module.scss';

type HTMLOrSVGSVGElement = HTMLElement & SVGSVGElement;

export type IconProps = React.HTMLAttributes<HTMLElement> & {
  /**
   * Optional class name to tack onto the SVG element.
   */
  className?: string;
  /**
   * Convenience prop to apply a color token to the icon, e.g. `gray40`.
   * Defaults to `inherit` color from its ancestors.
   */
  color?: string;
  /**
   * Renders our legacy font icon instead. When enabled, the name must match one
   * from our legacy icon set, e.g. `icon-sparkles`.
   */
  isFont?: boolean;
  /**
   * Icon name, e.g. `activities`
   */
  name: string; // todo: create a dynamic union type here from manifest of names?
  /**
   * Convenience prop to scale by preset sizes or the number of pixels. Defaults
   * to `inherit` size from its ancestors.
   */
  size?: number | 'inherit' | 'lg' | 'md' | 'sm';
  /**
   * Controls the SVG stroke weight, which can be made thicker or thinner to
   * control the icon weight.
   */
  strokeWeight?: number;

  style?: React.CSSProperties & {
    '--icon-color'?: string;
    '--icon-size'?: string;
    '--icon-stroke-width'?: string;
  };

  /**
   * Includes an SVG "title" that describes the icon for better a11y. By
   * default, the icon name is used but can otherwise be overrided.
   */
  title?: string;

  /**
   * Optional class name to apply onto the SVG wrapper container.
   */
  wrapperClassName?: string;
};

const Icon = memo(
  forwardRef<HTMLOrSVGSVGElement, IconProps>(function Icon(
    {
      className,
      color = 'inherit',
      isFont = false,
      name,
      size = 'inherit',
      strokeWeight = 2,
      style,
      title,
      wrapperClassName,
      ...attrs
    }: IconProps,
    ref,
  ) {
    const bem = useClassy(classes, 'Icon');
    const uid = useUniqueId('Icon');

    const styles = useMemo(() => {
      return {
        ...style,
        '--icon-color': color === 'inherit' ? color : `var(--${color})`,
        '--icon-size': size === 'inherit' ? size : Number(size) ? `${size}px` : `var(--icon-${size})`,
        '--icon-stroke-width': `${strokeWeight}px`,
      } as React.CSSProperties;
    }, [color, size, strokeWeight, style]);

    // Props to be spread across both SVG and font icon templates.
    const commonProps = useMemo(
      () => ({
        'data-name': name,
        role: 'img',
        style: styles,
      }),
      [name, styles],
    );

    // Render font icon if legacy flag is set.
    if (isFont) {
      return <i ref={ref} aria-label={title} className={bem('&', name, className)} {...commonProps} {...attrs}></i>;
    }

    // Render our async SVGR icon with a fallback.
    return (
      <span ref={ref} className={`IconWrapper ${bem('&-wrapper', wrapperClassName)}`}>
        <AsyncIcon
          className={`Icon ${bem('&', '-svg', className)}`}
          fallback={<span className={bem('&', '-empty', className)} style={styles} />}
          title={title}
          titleId={title ? uid(name) : undefined}
          {...commonProps}
          {...attrs}
        />
      </span>
    );
  }),
);

/**
 * Renders an SVG icon based on the provided `name`. Default color and size are
 * inherited from its parent but can be overridden with props if needed.
 * @link https://next.readme.ninja/ui/#/Foundations/Icons
 * @example
 * ```tsx
 * <Icon name='airplay' />
 * <Icon name='airplay' size='sm' />
 * <Icon name='airplay' color='green40' size={48} />
 * ```
 */
export default Icon;
