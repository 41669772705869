import { useContext, useEffect, useState } from 'react';

import { APIBaseUrlContext } from '@core/context';
import type { HTTPError } from '@core/utils/types/errors';

import fetcher from './fetcher';

/**
 * A hook that wraps a fetcher without SWR for writing data to the Readme API.
 *
 * @deprecated Use the `@core/hooks/useReadmeApi/fetcher` function instead.
 */
export default function useReadmeApiWrite<T>(path: string, options: Parameters<typeof fetcher>[1], isReady = true) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<HTTPError | null>(null);

  const apiBaseUrl = useContext(APIBaseUrlContext);
  const host = apiBaseUrl !== '/' ? apiBaseUrl : '';

  useEffect(() => {
    if (!isReady || !path) return;
    setData(null);
    setError(null);

    async function fetchData() {
      try {
        const res = await fetcher<T>(`${host}/${path}`, options);
        setData(res);
      } catch (err) {
        setError(err as HTTPError);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, path]);

  return {
    data,
    error,
    isLoading: isReady && path ? !data && !error : false,
    // This.... is kind of a hack? There might be a better way to do this.
    // In certain circumstances we want to be able to use this hook based on external state combined with the isLoading flag.
    // If, in that circumstance, we also want to be able to trigger it more than once, we'll need to reset the data.
    reset: () => {
      setData(null);
      setError(null);
    },
  };
}
